import React, { useContext, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { GlobalContext } from '../GlobalContext';
import { colorConstants, populationConstants } from '../Constants';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { isSameRegion, isSubRegion } from '../RegionConcepts/RegionUtils';
import DataOptionList from "./DataOptionList";
import {getDefaultRegionLabel} from '../RegionConcepts/RegionNameCustomization';
import '../css_config/ReactDatePickerStyles.css';

const panelStyle = {
    // width:'10%',
    // height:'100%',
    // left: '0px',
    // position:'relative',
    // overflow: 'auto',
    backgroundColor: '#f8f9fa',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // display: 'block',
    textAlign: 'center',
    padding: '2px 2px 2px 2px'
}

const divStyle = {
    textAlign: 'left',
    display: 'inline-block'
}

const elmntStyle = {
    margin: '2px 5px 10px 5px'
}

function MapControlPanelBottom() {
    const {simulationState, setSimulationState, handleToggleSimulation, handleColorCategoryChange, MapDisplayDate, setMapDisplayDate} = useContext(GlobalContext);
    const {MapDataCategoryList, setMapDataCategoryList, MapDataCategory, setMapDataCategory} = useContext(GlobalContext);
    const {CountryInfo, StateInfo, CountyInfo, RegionOnMap, setPickedCountry, PickedCountry, SetRegionOnMap, loadStateInfo, loadCountyInfo,
            setPickedDataSource, PickedDataSource, loadCovidObservationsForMap} = useContext(GlobalContext);
    const [dropdownOpen_country, setDropdownOpen_country] = useState(false);
    const [dropdownOpen_state, setDropdownOpen_state] = useState(false);
    // const [dropdownOpen_county, setDropdownOpen_county] = useState(false);
    const [dropdownDataSource, setDropdownDataSource] = useState(false);
    const toggle_country = () => setDropdownOpen_country(prevState => !prevState);
    const toggle_state = () => setDropdownOpen_state(prevState => !prevState);
    // const toggle_county = () => setDropdownOpen_county(prevState => !prevState);
    const toggleDatasource = () => setDropdownDataSource(prevState => !prevState);

    useLayoutEffect(() => {
        if (simulationState.showObservedCaseGraphs) {
            loadCovidObservationsForMap(RegionOnMap)
        }
    }, [PickedDataSource, RegionOnMap, simulationState.showObservedCaseGraphs, MapDisplayDate]);


    useEffect(()=>{
      if (RegionOnMap.length == 1) {
        loadStateInfo(PickedCountry);
      } else if (RegionOnMap.length == 2 && RegionOnMap[0] === 'United States')  {
        loadCountyInfo(RegionOnMap);
      }
    }, [RegionOnMap]);

    const handleClick_Country = selectedCountry => () => {
        if (selectedCountry === PickedCountry) return;
        if (selectedCountry === "Peru") {
            setPickedDataSource("UNSA");
            SetRegionOnMap(["Peru"])
        }
        if (selectedCountry === "United States") {
            setPickedDataSource("NYTimes");
            SetRegionOnMap(['United States']);
        }

        setPickedCountry(selectedCountry)
//        loadStateInfo(selectedCountry);
    }
    
    const handleClick_State = (regionName) => () => {
        let selectedRegion = (regionName == '')? [PickedCountry] : [PickedCountry, regionName];
        if (isSameRegion(selectedRegion, RegionOnMap)) return;
        SetRegionOnMap(selectedRegion);
    }

    const handleClick_Datasource = selectedDatasource => () => {
        if (PickedCountry == selectedDatasource) return; 
        setPickedDataSource(selectedDatasource)
    }

    const datasourceOptions = [
        {id:'NYTimes', displayName:'NYTimes'},{id:'UNSA', displayName:'UNSA'},{id:'JHU', displayName:'JHU'}, 
        {id:'CovidTrackingProject', displayName:'Covid Tracking Project'}];


    return (
        <div key="mapview-control" style={panelStyle}>
            <div style={elmntStyle} key="mapview-control-region-selector" style={{display:"inline-block"}}>
                <Dropdown isOpen={dropdownOpen_country} toggle={toggle_country}  style={{display:"inline-block"}}>
                    <DropdownToggle caret size="sm" color="light">
                    {(PickedCountry!== undefined)?PickedCountry:'select country'}
                    </DropdownToggle>
                    <DropdownMenu>
                    {
                        CountryInfo.map((item)=>{
                            return <DropdownItem key={'dropdown:'+item['name']} onClick={handleClick_Country(item['name'])}>
                                    {item['name']}
                                </DropdownItem>
                        })
                    }
                    </DropdownMenu>
                </Dropdown>

                { (PickedCountry=='United States') &&
                 <Dropdown key='map-control-subregion-dropdown' isOpen={dropdownOpen_state} toggle={toggle_state} style={{display:"inline-block"}}>
                    <DropdownToggle caret size="sm" color="light">
                        {RegionOnMap.length>1? RegionOnMap[1] : getDefaultRegionLabel(PickedCountry)}
                    </DropdownToggle>

                    <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                        <DropdownItem key={'dropdown:map:select-a-state'} onClick={handleClick_State('', '')}>select state</DropdownItem>
                    {
                        StateInfo.map((item)=>{
                        return <DropdownItem key={'dropdown:map:'+item['name']} onClick={handleClick_State(item['name'])}>
                            {item['name']}
                            </DropdownItem>
                        })
                    }
                    </DropdownMenu>
                </Dropdown>}
            </div>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Dropdown key="mapview-control-data-selector" isOpen={dropdownDataSource} toggle={toggleDatasource} style={{display:"inline-block"}}>
                <DropdownToggle caret size="sm" color="light">
                  {(PickedDataSource=== '')? 'select a datasource' : PickedDataSource}
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                {
                    datasourceOptions.map((item)=>{
                        if ((PickedCountry === "Peru" && item.id === "UNSA") ||
                            (PickedCountry !== "Peru" && item.id !== "UNSA")) {
                          return <DropdownItem key={item.id} onClick={handleClick_Datasource(item.id)}>{item.displayName}</DropdownItem>
                        }
                      })
                }
                </DropdownMenu>
            </Dropdown>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <DatePicker selected={startMapDate} onChange={date => setMapStartDate(date)} style={{ width: "100px"}}/> */}
        {/* <DatePicker selected={endMapDate}   onChange={date => setMapEndDate(date)} />
        <br />
        <button onClick={()=>{loadDataForMap(RegionOnMap)}}>Load Data</button> */}

            <div key="mapview-control-date-selector" style={{display:"inline-block"}}>
                <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
                    <DatePicker selected={MapDisplayDate} onChange={date => {setMapDisplayDate(date) }}/>
                </div>
                &nbsp;
                <button type='button' onClick={() =>
                    {
                        let datecpy = new Date(MapDisplayDate)
                        datecpy.setDate(datecpy.getDate()-1)
                        setMapDisplayDate(datecpy)
                    }}
                    style={{marginRight: '2px'}}
                >
                    <b>&lt;&lt;</b>
                </button>
                <button type='button' onClick={() =>
                    {
                        let datecpy = new Date(MapDisplayDate)
                        datecpy.setDate(datecpy.getDate()+1)
                        setMapDisplayDate(datecpy)
                    }}
                >
                    <b>&gt;&gt;</b>
                </button>
            </div>

            <hr />
            <div style={divStyle} >
                <b>Color Map By</b>

                &nbsp;&nbsp;Category:

                {MapDataCategoryList.length>0 && <select style={elmntStyle} onChange={handleColorCategoryChange}>
                    {MapDataCategoryList.map(category => {return <option key={'map-color-by-cate-'+category} value={category}>{category}</option>})}
                </select>}
                &nbsp;&nbsp;Color Legend:
                <img src='ScaleR.bmp' alt='scaleR' style={{ borderStyle: 'solid', borderWidth: '1px'}} />
                <br />
            </div>
             <div style={divStyle}>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>Data Type:</b>&nbsp;&nbsp;&nbsp;
                <DataOptionList />
             </div>
        </div>
    );
}

export default MapControlPanelBottom;
