const Shapefile_projections = [{country:"United States", projection:"geoAlbersUsa"}, {country:"Peru", projection:"geoMercator"}]



const Shapefiles_Peru = [
    {id:["Peru"], url:"https://raw.githubusercontent.com/deldersveld/topojson/master/countries/peru/peru-departments.json",subregion_key:["properties", "NOMBDEP"]}
]

const COUNTRY_Alpha_3_Code = [{name:"United States", code:"USA"}, {name:"Peru", code:"PER"}, {name:"India", code:"IND"},
{name:"China", code:"CHN"}, {name:"Germany", code:"DEU"} ];

export {Shapefile_projections, Shapefiles_Peru, COUNTRY_Alpha_3_Code};