import React, {useContext, useLayoutEffect, useEffect, useRef, useState} from 'react';
import { GlobalContext } from '../GlobalContext';
import {getColorByDataSourceDisplayName, getDataSource, transformData} from './Utils';
import {TimeSeriesPerDataCategory} from "./TimeSeriesPerDataCategory";

const TimeSeriesView = () => {
    const {ObservationDataModeling, PredictionData, SelectedRegionOfModelingView} = useContext(GlobalContext);
    const DATA_POINTS = {
        'cases': ['modeledCases', 'observedCases'],
        'deaths': ['modeledDeaths', 'observedDeaths'],
        'hospitalization': ['modeledHospitalization', 'observedHospitalization']
    };
    /* timeSeriesData = [{'type': 'Observed', data:[{observedCases=0, observedDeaths='', date=, timestr=}]}]
    each item in timeseriesData is a datasource, e.g., oberseved, predicted, etc.
    * */
    const [timeSeriesData, setTimeSeriesData] = useState(transformData(ObservationDataModeling, PredictionData, SelectedRegionOfModelingView));
    const [toolTipConfig, setToolTipConfig] = useState({'date': null, 'x':0, 'y':0, 'visibility': 'hidden'});
    const [tooltipData, setTooltipData] = useState([]);
    useLayoutEffect(() => {
        // console.log('TimeSeriesView: useLayoutEffect');
        let data = transformData(ObservationDataModeling, PredictionData, SelectedRegionOfModelingView);
        console.log(data)
        setTimeSeriesData(data)
        console.log(PredictionData)
        console.log(SelectedRegionOfModelingView)
    }, [ObservationDataModeling, PredictionData, SelectedRegionOfModelingView]);

    useEffect(()=>{
        // console.log([pickedDate, coorX, coorY])
        // console.log(toolTipConfig)
        let pickedDate = toolTipConfig.date;
        let coorX = toolTipConfig.x;
        let coorY = toolTipConfig.y;
        if (pickedDate === null) {
            return
        }

        // console.log(timeSeriesData)
        let picked_data = timeSeriesData.map(function (datasource) {
            let cur = datasource.data.filter(d =>
                d.date.getFullYear() == pickedDate.getFullYear() &&
                d.date.getMonth() == pickedDate.getMonth() &&
                d.date.getDate() == pickedDate.getDate()
            );
            return {'datasource': datasource.type, 'data': cur[0]}
        });

        // console.log(picked_data)

        let display_text = 'Date: ' + (toolTipConfig.date? toolTipConfig.date.toISOString().substring(0, 10) : '') + '\n'
        picked_data.map((item, index) => {
            if (item.data === undefined || item.data === null) { return }
            display_text = display_text + (item.datasource + '\n')
            Object.keys(item.data).map((key) => {
                let display = false
                Object.keys(DATA_POINTS).map((dataCategory) => {
                    if (DATA_POINTS[dataCategory].includes(key)) {
                        display = true
                    }
                })
                if (display === true)
                    display_text = display_text + (key + ':' + item.data[key] + '\n')
            })
        })
        console.log(display_text)
        setTooltipData(display_text)
        var div = document.getElementById('TimeSeries-tooltip-mouse-hover-div-id');
        div.style.left = coorX + 'px';
        div.style.top = coorY + 'px';
        div.style.visibility = 'visible'
    }, [toolTipConfig])

    return (
        <div>
            <h1>Time Series View</h1>
            <div id={'TimeSeries-tooltip-mouse-hover-div-id'}
                 key={'TimeSeries-tooltip-mouse-hover-div-key'}
                 style={{visibility: 'hidden', position: 'absolute', zIndex: 999,
                     whiteSpace: 'pre-line', backgroundColor: '#EBEBEB'}}>
                {(toolTipConfig.visibility === 'visible' && tooltipData.length > 0 ) &&
                    <div key={'TimeSeries-tooltip-mouse-hover-label-key'}>
                        {tooltipData}
                    </div>
                }
            </div>
            {
               Object.keys(DATA_POINTS).map((item) => {
                    return <div key={'timeseries-div-' +item}>
                        <h2>{item}</h2>
                        <TimeSeriesPerDataCategory
                           key={'TimeSeriesPerDataCategory-' + item}
                           id={'TimeSeriesPerDataCategory-' + item}
                           category={item}
                           param_dataCategoryList={DATA_POINTS[item]}
                           param_plotData={timeSeriesData}
                           callbackFunc={setToolTipConfig}
                           toolTipConfig={toolTipConfig}
                           />
                        </div>
                    })
            }
        </div>
    );
}

export default TimeSeriesView;