import React, {useContext, useEffect, useState} from 'react';
// import { GlobalContext } from '../GlobalContext';
import {MultiLineChart} from "./MultiLineChart";
import {getDataSource,getColorByDataSourceDisplayName} from "./Utils";
import {Card, CardBody} from "reactstrap";

const TimeSeriesPerDataCategory = ({category, param_dataCategoryList, param_plotData, callbackFunc, toolTipConfig}) => {
    const [DataVisibility, setDataVisibility] = useState(Object.assign({}, ...getDataSource().map((x) => ({[x]: true}))));
    // const [tooltipVisibility, setTooltipVisibility] = useState(false);
    // const [tooltipData, setTooltipData] = useState([]);
    function updateModelVisibility(e) {
       let clickedModel = e.target.id;
        setDataVisibility(prevState => ({
            ...prevState,
           [clickedModel]: !prevState[clickedModel]
       }))
    }


    // console.log(category)
    // // console.log(tooltipData)
    // console.log(toolTipConfig.category === category)

    return (
        <div key={"TimeSeriesPerDataCategory-" + category}
             id={"TimeSeriesPerDataCategory-" + category}>

            <MultiLineChart
                key={'TimeSeriesPerDataCategory-MultiLineChart-' + category}
                id={'TimeSeriesPerDataCategory-MultiLineChart-' + category}
                category={category}
                dataCategoryList={param_dataCategoryList}
                plotData={param_plotData}
                callbackfn={callbackFunc}
            />
            {
                Object.keys(DataVisibility).map((displayName)=>{
                    return <label key={"TimeSeriesPerDataCategory-checkbox-" + displayName} >
                            &nbsp;&nbsp;<input type="checkbox"
                                               name={'checkbox-' + displayName}
                                               key={'checkbox-' + displayName}
                                               id={displayName}
                                               checked={DataVisibility[displayName]}
                                               onChange={updateModelVisibility} />
                            <label key={"TimeSeriesPerDataCategory-text-" + displayName}
                                   style={{backgroundColor: getColorByDataSourceDisplayName(displayName)}}>
                                {displayName}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </label>;
                })
            }
        </div>
    );
}

export  {TimeSeriesPerDataCategory};