import React, {useContext, useEffect, useLayoutEffect, useState, useCallback }from 'react';
import LineChart from './LineChart';
import { GlobalContext} from '../GlobalContext';
// import { colorConstants, observedDataConstants, timeChartConstants, unemploymentDataConstants } from './Constants'; 
import { simulationModule } from "../API";
// import { csv } from "d3-fetch";
import * as d3 from "d3";
import * as utilities from './Utilities'
import DecisionOptionPanel from './DecisionOptionPanel'
import ScenarioOptionPanel from './ScenarioOptionPanel'
//import {Rectangle, Circle, Ellipse, CornerBox, Triangle} from 'react-shapes';
import './decision-measure.css'

const DecisionMeasureView = () => {
    const {PredictionData, ObservationData} 
            = useContext(GlobalContext)
    // console.log(PredictionDataForComparison)
    // console.log(ObservationData)
    const [Scenarios, setScenarios] = useState([])
    /*SelectedScenarios = {
        scenarioName:[{isSimulated:true/false, data:[], events:[]}]
    }*/
    const [SelectedScenarios, setSelectedScenarios] = useState([])
    const [ScenarioData, setScenarioData] = useState([])
    const [DataOptions, setDataOptions] = useState([])
    const [ContextMenu, setContextMenu] = useState(
                        {visible:false, events:[], top:0, left:0, date:""});
    
    useEffect(() => {
        var scenarioOptions = PredictionData.map((item)=>{
                return {'fips':item.fips, 'modelname':item.modelname}
        })
        if (scenarioOptions.length > 0 && scenarioOptions[0].fips !='')
            setScenarios(scenarioOptions)
    }, [PredictionData]);

    const handleInteractivePlot = useCallback((x_date, index, pos) => {
        // console.log([x_date, index]);
        //show the decision panel
        var dataCopy = {}
        if (ContextMenu.visible) {
            dataCopy.visible = false;
            dataCopy.events  = [];
            dataCopy.left  = 0;
            dataCopy.top   = 0;
            dataCopy.date  = "";
        }
        else {
            let scenarioName = utilities.getScenarioName(SelectedScenarios[0]);
            dataCopy.visible = true;
            console.log(ScenarioData[scenarioName])
            if (ScenarioData[scenarioName].length == 1 || index==0) {
                dataCopy.events = []
            }
            else{
                let tuple = ScenarioData[scenarioName][index];
                dataCopy.events = ('events' in tuple)? tuple.events:[];
            }
            dataCopy.top = pos[1]
            dataCopy.left = pos[0]
            dataCopy.date = x_date.toISOString().slice(0,10);
        }
        setContextMenu(dataCopy);
    });

    const handleScenariosSelection = useCallback((clickedScenarioOption) => {
        if (SelectedScenarios.findIndex(x=>
            x.fips == clickedScenarioOption.fips && 
            x.model == clickedScenarioOption.modelname) >= 0) 
            return;
        
        setSelectedScenarios([clickedScenarioOption]);
        let scenarioName = utilities.getScenarioName(clickedScenarioOption);
        let obsIndex = ObservationData.findIndex(x=>
            x.fips == clickedScenarioOption.fips)
        let modelIndex = PredictionData.findIndex(x=>
            x.fips == clickedScenarioOption.fips && 
            x.modelname == clickedScenarioOption.modelname)
        if (obsIndex < 0 || modelIndex < 0) {
            console.log("Error: cannot find data")
            return;
        }
        
        if (!(scenarioName in ScenarioData)) {
            console.log("add")
            let datacopy = Object.assign(ScenarioData);
            datacopy[scenarioName] = [
                {'isSimulated':false, 'data':ObservationData[obsIndex].data}];
            setScenarioData(datacopy);
        }
    });

    const handleDataOptionSelection = useCallback((clickedDataOption)=>{
        setDataOptions(clickedDataOption);
    });

    const selectEventCategory = useCallback((eventTags)=>{
        console.log(eventTags);
        console.log(ContextMenu);
        var eventIntervals = [];
        ContextMenu.events.forEach((timeInterval)=>{
            eventIntervals.push(timeInterval);
        });
        eventTags.forEach((eventTag)=>{
           let i = eventIntervals.findIndex(x=>x.name==eventTag);
           if (i <0) {
               eventIntervals.push({'name':eventTag, 'begin': ContextMenu.date});
           }
        });
        console.log(eventIntervals);

        fetch(simulationModule, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                model:  SelectedScenarios[0].modelname,
                fips:   SelectedScenarios[0].fips,
                events: eventIntervals
            })
        })
        .then(res => 
            res.json()
        )
        .then(data => {
            console.log(data);
            let index = data.findIndex(x=>x.timestr==ContextMenu.date);
            console.log(index)
            index = (index < 0)? 0: index;
            let simulatedData = data.slice(index)
            let scenarioDataCopy = Object.assign(ScenarioData);
            let scenarioname = utilities.getScenarioName(SelectedScenarios[0])
            scenarioDataCopy[scenarioname].push({'isSimulated':true, 'data':simulatedData, 'events':eventIntervals});
            setScenarioData(scenarioDataCopy);
        });
    })

    const getLegendTag = (item)=>{
        let str = ' (';
        item.events.forEach(e=>{
            str = str + e.name + ','
        })
        str = str.substring(0, str.length-1) + ')';
        return str;
    }

    return  (
        <div key={'DecisionMeasureView'} style={{backgroundColor: '#f8f9fa'}}>
            <h1 className="title">{'Decision Measure'}</h1> 
            <LineChart scenarioDataFull = {ScenarioData}
                dataVisibilityBasic = {DataOptions}
                selectedScenario = {SelectedScenarios}
                prewidth = {700}
                preheight = {430}
                callbackfn = {handleInteractivePlot}/>
            { ContextMenu.visible && 
                <div style={{
                    position:'absolute',
                    top: (ContextMenu.top).toString()+"px", 
                    left: (ContextMenu.left+15).toString()+"px", 
                    backgroundColor:"#f0f0f0",
                    //  height:"200px",
                    width:"170px",
                }}>
                    <DecisionOptionPanel key = {'DecisionOptionPanel'}
                        handleEventCategorySelection={selectEventCategory}
                        defaultVal={ContextMenu.events}/>
                </div>
            }
            { SelectedScenarios.length>0 && ScenarioData[utilities.getScenarioName(SelectedScenarios[0])].length > 0 &&
                ScenarioData[utilities.getScenarioName(SelectedScenarios[0])].map((item, i)=>{
                    return (<div key={'DecisionMeasureView-legend-' + i.toString()} style={{display:"inline-block"}}>
                        {/*<Rectangle width={60} height={5}
                            fill={{color:(item.isSimulated)? "#5ab4ac":"#d8b365"}}></Rectangle>*/}
                        <div class="rectangle">
                        </div>
                        <label>{(item.isSimulated)?('Simulation'+getLegendTag(item)):'Observation'}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>)
                })
            }
            <br />
            <ScenarioOptionPanel key = {'ScenarioOptionPanel'}
                handleScenarioSelection = {handleScenariosSelection} 
                handleDataOptionSelection = {handleDataOptionSelection}
                selectedScenario = {SelectedScenarios}
                scenarioList={Scenarios}/>
        </div>
    );
}

export default DecisionMeasureView;