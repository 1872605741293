
import {Shapefile_projections, Shapefiles_Peru, COUNTRY_Alpha_3_Code} from './RegionConstants'
import {Shapefiles_US} from './Shapefile_Url_US'

const getLookup = (country_str) => {
    if (country_str == "United States") {
        return Shapefiles_US
    } else if (country_str == "Peru") {
        return Shapefiles_Peru
    }
}

const isSameRegion = (regionId1, regionId2) =>{
    if(regionId1.length != regionId2.length) {
        return false
    }

    for(let i=0; i<regionId1.length; i++) {
        if (regionId1[i] != regionId2[i]) {
            return false
        }
    }
    return true
}

const isSubRegion = (region, subregion) => {
    if ((region.length +1) != subregion.length) {
        return false
    }
    for(let i=0; i<region.length; i++) {
        if (region[i] != subregion[i])
            return false
    }
    return true
}

const getShapefileUrl = (country_str, region)=>{
    // console.log([country_str, region])
    const dictObj = getLookup(country_str)
    let adjustRegion = [region[0]]
    if (country_str == 'United States') {
        if (region.length>1) {
            adjustRegion.push(region[1])
        }
    }
    // console.log(adjustRegion)
    let arr = dictObj.filter(element => isSameRegion(element.id, adjustRegion))
    return [arr[0].url, arr[0].subregion_key]
}

const getMapProjection = (regionId) => {
   let arr = Shapefile_projections.filter(element=>element.country==regionId[0])
   return arr[0].projection
}

export {getShapefileUrl, isSameRegion, isSubRegion, getMapProjection}