const colorsLeftAxis = ["#4575b4","#74add1","#abd9e9"];
const colorsRightAxis = [
        "#fdb462", "#f2ca5a", "#fccde5", "#cab2d6", 
        "#fb8072", "#b3de69", "#ccebc5", "#8dd3c7", 
        "#ffed6f", "#b15928", "#1f78b4", "#6a3d9a"];

// const colorsRightAxis = [   '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', 
//                             '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabed4', 
//                             '#469990', '#dcbeff', '#9a6324', '#fffac8', '#800000', 
//                             '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9'];

const colorsMitigation = ["#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]
const colorExcerbation = ["#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"]
const colorNoneCategory = "#d9d9d9"
const colorReferenceArea = ["#d9ef8b"];
const scenarioGlyphs = ["triangle", "dot", "rect"]
const colorsForBarChart = ["#6a51a3","#bcbddc","#9e9ac8","#807dba"]
export {colorsLeftAxis, colorsRightAxis, colorReferenceArea, scenarioGlyphs,colorsForBarChart, colorsMitigation, colorExcerbation, colorNoneCategory};