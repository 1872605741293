
function getDefaultRegionLabel (country) {
  let tag = ''
 if (country == 'United States') {
    tag = 'select state'
 } else if (country == 'Peru') {
    tag = 'select department'
 }
 return tag
}

export {getDefaultRegionLabel}