import React, { Component, useContext, useState, useEffect } from 'react'
import {withResizeDetector} from 'react-resize-detector';
import NodeLinkGraph from './NodeLinkGraph'

const AdaptiveStateTransitionView = (({ width, height }) =>{
    const  [selectedModel, setSelectedModel] = useState('oklahoma_simulation')
    const  [modelStates, setModelStates] = useState({nodes:[], links:[]})
    useEffect(()=>{
        fetch(selectedModel+'.json', {
           headers : {
             'Content-Type': 'application/json',
             'Accept': 'application/json'
            }
         })
        .then(function(response) {
            return response.json()
        })
        .then(function(myJson) {
            setModelStates(myJson);
        });
    }, [selectedModel])

    return (
        <div key="StateTransitionView">
             <h1 className="title">{'State Transition'}</h1> 
         {modelStates.nodes.length > 0 && <NodeLinkGraph size={[500,  450]} data={modelStates}
                        margin ={{top: 20, right: 20, bottom: 20, left: 20}} />}
        </div>);
});

const StateTransitionView = withResizeDetector(AdaptiveStateTransitionView);

export default StateTransitionView