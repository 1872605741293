import React, {useContext, useState, useRef, useEffect} from 'react'
import { GlobalContext } from '../GlobalContext';
import {Button } from 'reactstrap';
import {colorsRightAxis} from '../model_comparison/model_comparison_utilies'



const EventCategorySelectionPanel = ({width, height, posLeft, posTop, 
        handleEventCategorySelection, defaultVal}) =>{
  const {EventCategories, PolicyDataset, SelectedRegionOfEventView} = useContext(GlobalContext);
  const [indexOfCheckedRadio, setIndexOfCheckedRadio] = useState(defaultVal);
  const [displayEvents, setDisplayEvents] = useState([])
  useEffect(()=>{
    let arrs = EventCategories.filter(x=>x.fips == SelectedRegionOfEventView && x.dataset ==PolicyDataset)
    setDisplayEvents(arrs);
  }, [EventCategories])

  const selectEventCategory = ((e) => {
    setIndexOfCheckedRadio(e.target.value)
    
    let str = (e.target.value <0 || displayEvents.length == 0)?null:
        displayEvents[0]['categories'][e.target.value]
    handleEventCategorySelection(str)
  })
return (<>
        <label >Select event category:</label> 
        <label ><input key= {"EventCategorySelection-0"} 
                type="radio"
                checked={-1==indexOfCheckedRadio}
                onChange={selectEventCategory}
                value={-1}/> None
        </label>
        {displayEvents.length>0 &&displayEvents[0]['categories'].map((category, i)=>{
        return  <label key={"EventCategorySelectionPanel-"+category}
                style={{display:"block", 
                color:colorsRightAxis[i%colorsRightAxis.length]}}>
                <input type="radio"
                        checked={(i)==indexOfCheckedRadio}
                        onChange={selectEventCategory}
                        value={i}/>
                {category}
                </label>
        })}
    </>)
}

export default EventCategorySelectionPanel;

