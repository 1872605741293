import React, {useContext, useState, useRef, useEffect} from 'react'
import { GlobalContext } from '../GlobalContext';
import {Button} from 'reactstrap';
import {colorsRightAxis} from '../model_comparison/model_comparison_utilies'
import {isSameRegion} from "../RegionConcepts/RegionUtils";

var lodash_1 = require("lodash");

const VisEventCategory = ({width, height}) => {
  const {EventCategories, setEventCategories, PolicyDataset, SelectedRegionOfEventView} = useContext(GlobalContext);
  const [inputCategory, setInputCategory] = useState('')
  const [arrs, setArrs] = useState([])
  // const inputRef = useRef([]);
  const removeCategory=((e) => {
    var res = lodash_1.cloneDeep(EventCategories)
    // console.log(EventCategories)
    let targetIndex = res.findIndex(x=>x.dataset == PolicyDataset && isSameRegion(x.region, SelectedRegionOfEventView))
    if (targetIndex > -1) {
      res[targetIndex].categories = res[targetIndex].categories.filter(x=>x != e.target.name)
      setEventCategories(res)
    }
  });

  const handleInputChange = ((e) => {
    setInputCategory(e.target.value)
  })

  const addCategory = ((e)=>{
    var res = lodash_1.cloneDeep(EventCategories)//[PolicyDataset].slice(0);
    if (inputCategory != '') {
      // if (res[PolicyDataset].findIndex(x=> x == inputCategory) <0) {
      //   res[PolicyDataset].push(inputCategory)
      //   setEventCategories(res);
      // }
      let targetIndex = res.findIndex(x=>x.dataset == PolicyDataset && isSameRegion(x.region, SelectedRegionOfEventView))
      if (targetIndex > -1) {
        if (res[targetIndex].categories.findIndex(x=>x==inputCategory) < 0 ) {
          res[targetIndex].categories.push(inputCategory)//e.target.name)
          setEventCategories(res)
        }
      }
      setInputCategory('')
    }
  })

  useEffect(()=>{
    let data = EventCategories.filter(x => isSameRegion(x.region, SelectedRegionOfEventView) && x.dataset == PolicyDataset)
    setArrs(data);
//    console.log(EventCategories)
//    console.log(data)
  }, [EventCategories, SelectedRegionOfEventView, PolicyDataset])

  return <div key="Event-Category" width={width}
              style={{backgroundColor:"#efedf5", paddingBottom:"10px"}}>
        <h4 style={{textAlign: 'left'}}>Event Category</h4> 
        {
          arrs.length >0 && arrs[0]['categories'].map((category, i)=>{
            return <div key={"Event-Category-" + category} style={{float:'left'}}>
            <Button outline  size="sm" style={{height:"25px", font:"small-caption", backgroundColor:"#ece2f0", color:"black"}}
                    onClick={removeCategory} name={category}>{'\xD7'}</Button>&nbsp;
            <label style={{backgroundColor:colorsRightAxis[i]}}>{category}</label>
            &nbsp;&nbsp;&nbsp;
            </div>
          })
        }
        <br />
        <label >Category Name:&nbsp;
          <input type="text" style={{width:"150px", float:"leaft", height:"25px"}}
            value={inputCategory} onChange={handleInputChange}/>
          &nbsp;
        <Button outline size="sm" style={{width:"150px", height:"25px",
          marginTop:"-1px", paddingTop:"0px"}} onClick={addCategory} >Add Category</Button>
          </label>
    </div>
}

export default VisEventCategory;