import React, { useState, useContext, useEffect } from "react";
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import MapControlPanelBottom from './MapControlPanelBottom'
import Map from '../map_view/Map'
// import ControlPanelBottom from "./ControlPanelBottom";
import MapBottomView from "./MapBottomView";
import Stack from 'react-bootstrap/Stack';

function MapView() {

    return (
    <div key="Panviz-mapview">
        <Stack>
            <Map />
            <MapBottomView />
            <MapControlPanelBottom />
        </Stack>
    </div>
    );

}

export default MapView;