import React, {useContext, useState, useRef} from 'react'
import { GlobalContext } from '../GlobalContext';
// import {Button } from 'reactstrap';
import { Container, Card, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
// import { getRegionId } from '../FunctionsCommon';

/*This view enables users to select particular regions. 
  After a region is selected, the view on top of this view will show the corresponding policies.*/

/** Alert: The logic used in this view is currently only for USA.
 */

const EventsRegionOptionPanel = ({
        RegionList, handleRegionSelection}) => {
  const {StateInfo, CountyInfo, loadCountyInfo, PolicyDataset, PickedCountry} = useContext(GlobalContext);
  // const [SelectedRegionFipsCode, setSelectedRegionFipsCode] = useState('USA+40'); //a unique id across country and its regions
  const [SelectedPolicyDataset, setSelectedPolicyDataset]   = useState(PolicyDataset)
  const [SelectedRegionCountry, setSelectedRegionCountry]   = useState(PickedCountry)
  const [SelectedRegionState, setSelectedRegionState]   = useState('Oklahoma')
  const [SelectedRegionCounty, setSelectedRegionCounty] = useState('')
  const [dropdownOpen_policy, setDropdownOpen_policy] = useState(false);
  const [dropdownOpen_country, setDropdownOpen_country] = useState(false);
  const [dropdownOpen_state, setDropdownOpen_state] = useState(false);
  const [dropdownOpen_county, setDropdownOpen_county] = useState(false);
  const toggle_policy = () => setDropdownOpen_policy(prevState => !prevState)
  const toggle_country = () => setDropdownOpen_country(prevState => !prevState);
  const toggle_state = () => setDropdownOpen_state(prevState => !prevState);
  const toggle_county = () => setDropdownOpen_county(prevState => !prevState);

  const handleClick_PolicyDataset = selectPolicyDataset=> () =>{
    if (selectPolicyDataset == SelectedPolicyDataset) return;
    setSelectedPolicyDataset(selectPolicyDataset)
    // setPolicyDataset(selectPolicyDataset)
  }
  
  const handleClick_Country = selectCountryStr => () => {
    if (selectCountryStr == SelectedRegionCountry) return;
    setSelectedRegionCountry(selectCountryStr)
    setSelectedRegionState('')
    setSelectedRegionCounty('')
  }

  const handleClick_State = selectedFipsCode => () => { 
      if (selectedFipsCode == SelectedRegionState) return;
      // if (selectedFipsCode == SelectedRegionFipsCode.substring(0,2)) return;
      if (selectedFipsCode != 'USA') {
        loadCountyInfo(selectedFipsCode);
      }
      setSelectedRegionState(selectedFipsCode)
      setSelectedRegionCounty('')
      // setSelectedRegionFipsCode(selectedFipsCode);
  }
  const handleClick_County = selectedFipsCode => () => {setSelectedRegionCounty(selectedFipsCode);}

  const handleButton = (e) => {
        // const regionId = getRegionId(SelectedRegionCountry, (SelectedRegionState==''? SelectedRegionCounty:SelectedRegionState))
        const region = [SelectedRegionCountry]
        if (SelectedRegionState != '') {
            region.push(SelectedRegionState);
            if (SelectedRegionCounty != '') {
                region.push(SelectedRegionCounty);
            }
        }
        handleRegionSelection(region, SelectedPolicyDataset);
  }

  const fipsCodeMap_state = StateInfo.reduce(function(map, obj) {
      map[obj.fips] = obj.name;
      return map;
  }, {});


  const fipsCodeMap_county = CountyInfo.reduce(function(map, obj) {
    map[obj.fips] = obj.name;
    return map;
  }, {});

// const arrs = SelectedRegionFipsCode.split("+")
// const pickedState = (arrs.length ==1)? undefined : arrs[1]//SelectedRegionFipsCode.substring(0,2);
// const pickedCounty = (arrs.length <3)? undefined : arrs[2]//(SelectedRegionFipsCode.length>2)? SelectedRegionFipsCode : '';

return (<>
        <label>Select Policy Data:&nbsp;&nbsp;</label>
        <Dropdown isOpen={dropdownOpen_policy} toggle={toggle_policy}  style={{display:"inline-block", }}>
              <DropdownToggle caret size="sm" color="light">
              {SelectedPolicyDataset}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem key={'dropdown:policy_dataset:us'} onClick={handleClick_PolicyDataset('United States')}>United States</DropdownItem>
                <DropdownItem key={'dropdown:policy_dataset:ok'} onClick={handleClick_PolicyDataset('Oklahoma Simulation')}>Oklahoma Simulation</DropdownItem>
                <DropdownItem key={'dropdown:policy_dataset:OxCGRT'} onClick={handleClick_PolicyDataset('OxCGRT')}>OxCGRT</DropdownItem>
                <DropdownItem key={'dropdown:policy_dataset:amp'} onClick={handleClick_PolicyDataset('COVIDAMP')}>COVID AMP</DropdownItem>
              </DropdownMenu>
        </Dropdown>
        <br />
        <label>Select Region:&nbsp;&nbsp;</label> 

        <Dropdown isOpen={dropdownOpen_country} toggle={toggle_country}  style={{display:"inline-block", }}>
              <DropdownToggle caret size="sm" color="light">
                {SelectedRegionCountry}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem key={'dropdown:events_country'} onClick={handleClick_Country('United States')}>United States</DropdownItem>
              </DropdownMenu>
        </Dropdown>&nbsp;&nbsp;&nbsp;&nbsp;

        <Dropdown isOpen={dropdownOpen_state} toggle={toggle_state}  style={{display:"inline-block"}}>
              <DropdownToggle caret size="sm" color="light">
                {(SelectedRegionState == '')? 'select a state': SelectedRegionState}
              </DropdownToggle>
              <DropdownMenu>
              <DropdownItem key={'dropdown:select-a-state'} onClick={handleClick_State('')}>select a state</DropdownItem>
                {
                   StateInfo.map((item)=>{
                     return <DropdownItem key={'dropdown:'+item['fips']} onClick={handleClick_State(item['name'])}>
                                {item['name']}
                            </DropdownItem>
                  })
                }
              </DropdownMenu>
        </Dropdown>&nbsp;&nbsp;&nbsp;&nbsp;

        <Dropdown isOpen={dropdownOpen_county} toggle={toggle_county}  style={{display:"inline-block", }}>
              <DropdownToggle caret size="sm" color="light">
                {(SelectedRegionCounty == '')? 'select a county' : SelectedRegionCounty}
              </DropdownToggle>
              <DropdownMenu>
              <DropdownItem key={'dropdown:select-a-county'} onClick={handleClick_County('')}>select a county</DropdownItem>
                {
                    CountyInfo.map((item) => {
                      return <DropdownItem key={'dropdown:'+item['fips']} onClick={handleClick_County(item['name'])}>
                                {item['name']}
                              </DropdownItem>
                   })
                }
              </DropdownMenu>
        </Dropdown>&nbsp;&nbsp;&nbsp;&nbsp;

        <Button outline size="sm" onClick={handleButton}>Submit</Button>
    </>)
}

export default EventsRegionOptionPanel;

