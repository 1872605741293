import {isSameRegion, isSubRegion} from "../RegionConcepts/RegionUtils";
import {convertDate2Str} from "../DateTimeUtils";
import {colorConstants} from "../Constants";



const isTimeMatched = (datestr, targetDate) => {
//    console.log(convertDate2Str(targetDate))
    return convertDate2Str(targetDate) == datestr;
}

function assignColor(data, colorCategory) {
//    console.log(data)
    const maxItem = data.reduce((prev, current) => (prev[colorCategory] > current[colorCategory]) ? prev : current)
    // const minItem = data.reduce((prev, current) => (prev[colorCategory] < current[colorCategory]) ? prev : current)
    const maxValue = maxItem[colorCategory]
//    console.log(maxValue)
    let extended_data = data.map(item => {
        let ratio = item[colorCategory] / maxValue
        let colorIndex = Math.min(colorConstants.colorMap.length-1, Math.ceil(ratio*colorConstants.colorMap.length))
        item['color'] = colorConstants.colorMap[colorIndex]
    })
//    console.log(data)
    let dict = Object.assign({}, ...data.map((x) => ({[x.mapId]: x})));
    return dict;
}

function transform(item, paramMapId = undefined, paramRegion = undefined) {
    let data = {}
    Object.keys(item).forEach(key => { data[key] = item[key]})
    data['color'] = colorConstants.colorMap[0]
    if (paramMapId != undefined) {
        data['mapId'] = paramMapId
    }
    return data
//    console.log(item)
//    Object.keys(item).forEach((col, i) =>{
//        if (col.toLowerCase().includes('case')) {
//            data[colorConstants.colorValues.colorAllSickValue] = item[col]
//        }
//        else if (col.toLowerCase().includes('death')) {
//            data[colorConstants.colorValues.colorAllDeadValue] = item[col]
//        }
//        else if (col.toLowerCase().includes('hospital')) {
//            data[colorConstants.colorValues.colorAllHospitalizedValue] = item[col]
//        }
//    })
//    data['mapId'] = (item.mapId == undefined)? paramMapId : item.mapId
//    data['region'] = (item.region == undefined)? paramRegion : item.region

}

function getObservedDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate){
    if (!isSameRegion(ObservationDataMap[0].region, RegionOnMap) || !isTimeMatched(ObservationDataMap[0].datestr, MapDisplayDate)){
            return [];
      }
     let data = []
    if (PickedCountry == 'United States') {
        // console.log(RegionOnMap)
        if (RegionOnMap.length == 1) {
            data = ObservationDataMap[0]['data']['states']
        } else if (RegionOnMap.length == 2) {
            data = ObservationDataMap[0]['data']['counties']
        }
    } else if (PickedCountry == 'Peru') {
            data = ObservationDataMap[0]['data']['states']
    }
    return data
}

function extractObservationDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate, colorCategory) {
    // console.log(ObservationDataMap)
    let results = {}
    if (!isSameRegion(ObservationDataMap[0].region, RegionOnMap) || !isTimeMatched(ObservationDataMap[0].datestr, MapDisplayDate)){
        return results;
    }
    let raw_data = getObservedDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate)
    let data = raw_data.map(item => transform(item))
    return assignColor(data, colorCategory)
}

function extractModelingDataForMapDisplay(PickedCountry, RegionOnMap, MapDisplayDate, PredictionData, MapDisplayDataOption, colorCategory) {
    let elements = PredictionData.filter(item => isSubRegion(RegionOnMap, item.region) && (item.modelname==MapDisplayDataOption) && (item.prediction.length>0))
//    console.log(elements)
//    console.log(MapDisplayDate)
    let data = elements.map(item =>{
//        console.log(item.prediction)
        let data_on_target_day = item.prediction.filter(e => isTimeMatched(e.timestr, MapDisplayDate))
//        console.log(data_on_target_day)
        if (data_on_target_day.length == 0)
            return undefined
        return transform(data_on_target_day[0], item.mapId, item.region)
    })
    let trimmedData = data.filter(item => item != undefined)
//     console.log(trimmedData)
    if (trimmedData.length ==0) {return {}}
    return assignColor(trimmedData, colorCategory)
}

export {extractObservationDataForMapDisplay, extractModelingDataForMapDisplay, getObservedDataForMapDisplay}