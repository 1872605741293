import {isSameRegion} from "../RegionConcepts/RegionUtils";
import {colorsForDataSource, ModelOptions} from "../Constants";

function getColorByDataSourceDisplayName(dataSourceDisplayName) {
    let colorId = (dataSourceDisplayName === 'Observed')? 0 : (ModelOptions.map(d=>d.displayName).indexOf(dataSourceDisplayName) + 1);
    return colorsForDataSource[colorId];
}

function getDataSource() {
    return ['Observed'].concat(ModelOptions.map(d => d.displayName));
}
function  transformData(ObservationDataModeling, PredictionData, SelectedRegionOfModelingView) {

    var obsDataIndex = ObservationDataModeling.findIndex(x => isSameRegion(x.region, SelectedRegionOfModelingView))
    if (obsDataIndex < 0) {
        return null;
    }
    const observedData = ObservationDataModeling[obsDataIndex].data;
    var predictionData = []
    PredictionData.forEach((item) => {
        if (isSameRegion(item.region, SelectedRegionOfModelingView)) {
            predictionData.push(item)
        }
    })

    var isObservationsReady = !(observedData == undefined || observedData.length < 1);
    var isPredictionReady = !(predictionData == undefined ||
        predictionData.length < 1 || predictionData[0].modelname == '');
    if (!(isObservationsReady || isPredictionReady)) {
        return null;
    }

    /*format: [
    {'type':'Observed', 'data':[]},
    {'type':'model1', 'data':[]},
    {'type':'model2', 'data':[]}]
    * */
    var plotData = [];

    if (isObservationsReady) {
        var obs = []
        for (var i = 0; i < observedData.length; i++) {
            var d = observedData[i];
            var item = {};
            var dateInfo = new Date(d['time'])
            var elem = obs.filter(v => (v.date.getFullYear() == dateInfo.getFullYear() &
                v.date.getMonth() == dateInfo.getMonth() &
                v.date.getDate() == dateInfo.getDate()))
            if (elem.length === 0) {
                Object.keys(d).forEach((varname) => {
                    if (varname == 'time') {
                        item[varname] = d[varname];
                        item['date'] = new Date(d[varname]);
                    } else {
                        item[varname] = d[varname];
                    }
                });
                obs.push(item);
            }
        }
        plotData.push({'type':'Observed', 'data': obs});
    }
    if (isPredictionReady) {
        predictionData.forEach(function (dataPerModel) {
            let modelName = dataPerModel['modelname'];
            var data = dataPerModel['prediction'];
            var dataModel = []
            data.forEach(function (d) {
                var dateInfo = new Date(d['time'])
                var item = null
                var elem = dataModel.filter(v => (v.date.getFullYear() == dateInfo.getFullYear() &
                    v.date.getMonth() == dateInfo.getMonth() & v.date.getDate() == dateInfo.getDate()))
                // console.log(elem)
                if (elem.length === 0) {
                    if (dateInfo.getFullYear() == 2021)
                        item = {'time': d['time'], 'date': dateInfo}
                    else
                        item = {'date': dateInfo}
                    Object.keys(d).forEach(function (key) {
                        item[key] = d[key];
                    });
                    dataModel.push(item);
                }
                // else {
                //     item = elem[0]
                // }

            })

            plotData.push({'type': ModelOptions.filter(d=> d.id === modelName)[0].displayName, 'data': dataModel});
        })
    }


    return plotData;
}

export {transformData, getDataSource, getColorByDataSourceDisplayName};