import React, {useContext, useState, useRef} from 'react'
import { GlobalContext } from '../GlobalContext';
import {withResizeDetector} from 'react-resize-detector';
// import { easeElastic } from 'd3-ease';
// import { lab } from 'd3-color';
import LinePlotDualYAxis from './LinePlotDualYAxis'
import {colorsRightAxis, scenarioGlyphs, colorsLeftAxis, colorReferenceArea, colorsForBarChart} from './model_comparison_utilies'
import transformDataV2 from './data_transform'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
// import DataDensityPlot from './DataDensityPlot';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const AdaptiveModelComparison = ({width, height}) => {
    const {PredictionData,EventCategories} = useContext(GlobalContext);
    const [EventVisibilities, setEventVisibility] = React.useState([]);
    const inputRef = useRef([]);
    const [legendEntries, tuples] = transformDataV2(PredictionData)
    const [DisplayedScenario, setDisplayedScenario] = React.useState('');
    const [dropdownOpen_scenario, setDropdownOpen_scenario] = useState(false);
    const toggle_scenario = () => setDropdownOpen_scenario(prevState => !prevState);
    const visOneCategory = (legends, category, width, height, scenarioName) => {
        // console.log(category)
        return <div key={"category-".concat(category.name)}>
            <h2 style={{textAlign: 'left'}}>{category.name}</h2> 
            {/* {category.hist != undefined && 
                <div key={category.name+"-hist"}></div>
            } */}
            {category.leftAxis != undefined && 
                <div key={category.name+"-line-plot"}>
                    <h6 style={{textAlign: 'left'}}>{category.leftAxis[0].name}</h6> 
                    <LinePlotDualYAxis key= {"model-param-dual-plot-"+category.name} size={[width,150]} 
                                margin ={{top: 20, right: 40, bottom: 20, left: 40}} 
                                data={category} rightAxisEvents={legends.eventsForRightAxis} 
                                eventCategoryLookup = {EventCategories}
                                shownEvents = {EventVisibilities}
                                pickedScenario = {scenarioName}/>
                    {/* { category.hist != undefined && 
                        category.hist.map((dataDensityEntry) => {
                            return (<div key={"model-param-density-plot-"+category.name + "-" + dataDensityEntry.name}>
                            <h6 style={{textAlign: 'left'}}>{dataDensityEntry.name}</h6> 
                            <DataDensityPlot data = {dataDensityEntry} size={[width,150]} 
                            margin ={{top: 20, right: 40, bottom: 20, left: 40}} /> </div>);
                        })
                    }
                    {category.singleValue != undefined &&
                        category.singleValue.map((entry)=>{
                            return <div key={"model-param-density-plot-"+category.name + "-single-value-" + entry.name}>
                            <BarChart key={"model-param-density-plot-"+category.name + "-single-value-" + entry.name + "-barchart"}
                                width={width} height={120} data={[entry]} margin={{ top: 15, right: 30, left: 20, bottom: 5}}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                {
                                    legends.scenario.map((key, i) =>{
                                        return <Bar dataKey={key} fill={colorsForBarChart[i]}
                                        key={"model-param-density-plot-"+category.name + "-single-value-" + entry.name+"-"+key} />
                                    })
                                }
                            </BarChart>
                            </div>
                        })
                    } */}
                </div>
            }
            {/* {category.singleValue != undefined && 
                <div key={category.name+"-singleValue"}></div>
            } */}
        </div>
    }

    const toggleEvent = ((e) =>{
        var events = [];
        Object.keys(inputRef.current).forEach(key =>{
            if(inputRef.current[key].name != e) {
                if(inputRef.current[key].checked)
                    events.push(inputRef.current[key].name)
            }
            else {
                if (inputRef.current[key].checked) {
                    inputRef.current[key].checked = false;
                }
                else {
                    events.push(inputRef.current[key].name)
                    inputRef.current[key].checked = false;
                }
            }

        });
        // console.log(events)
        setEventVisibility(events);
        // return events;
    });

    const selectScenarioType = e => () => {
        setDisplayedScenario(e)
        // console.log(DisplayedScenario)
      }
    
    const getIndexCheckedScenario = ((labels)=>{
        if (DisplayedScenario == '')
            return 0
        return labels.scenario.findIndex(x=>x==DisplayedScenario)
    })

    const getScenarioName = (() =>{
        let val = DisplayedScenario
        if (DisplayedScenario == '')
            if (legendEntries['scenario'] != undefined && legendEntries['scenario'].length >0)
                val = legendEntries["scenario"][0]
            
        return val
    })

    //buggy, and hard-coded
    const eventCategoryObj = ((scenarioName)=>{
        // console.log(scenarioName)
        // console.log(EventCategories)
        if (scenarioName == '') {
            return EventCategories[0]
        }
        else {
            let tokens = scenarioName.split(/[()]/)
//            console.log(tokens)
            let modelname = tokens[0]
            let regionInfo = tokens[1]
//            console.log(EventCategories)
            let modelname_dict = {'model-Oklahoma':'oklahoma', 'covidproject':'US'}
            let id = EventCategories.findIndex(x=>(x.region.toString()==regionInfo) && modelname_dict[modelname] == x.dataset)
            if (id<0) id = 0
            return EventCategories[id]
        }
    })

    const VisLegends = (props) => {
        const labels = props.labelmap
        // console.log(labels)
        return (<>
                { labels["scenario"].length>0 &&
                    <div key= "model-parameter-legend-scenario" style={{display: 'inline-block'}}> 
                        <h5 style={{float:'left'}}>Scenario:</h5>
                        { 
                            Object.keys(labels.scenario).length > 0 &&  
                            // <div style={{float:'left'}}>
                            //   {
                            //     labels.scenario.map((e, i) => {
                            //         return (<div key={"model-parameter-legend-scenario-" + i } style={{float:'left'}} value={e}>
                            //             <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            //             <input type="radio" name="scenarioType" value={e} checked={getIndexCheckedScenario(labels)==i} onChange={selectScenarioType}/>
                            //             <label>{e}</label>
                            //             {(scenarioGlyphs[i] == 'dot') && <Circle r={10}  fill={{color: colorsLeftAxis[1]}}/>}
                            //             {(scenarioGlyphs[i] == 'rect') && <Rectangle width={20} height={20} fill={{color: colorsLeftAxis[1]}}/>}
                            //             {(scenarioGlyphs[i] == 'triangle') && <Triangle width={20} height={20} fill={{color: colorsLeftAxis[1]}}/>}
                            //         </div>)
                            //     })
                            //   }
                            // </div>
                            /////////////////////////////////////////////
                            <Dropdown isOpen={dropdownOpen_scenario} toggle={toggle_scenario}>
                                <DropdownToggle caret size="sm" color="light">
                                    {DisplayedScenario==''?'select scenario':DisplayedScenario}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem key={"model-parameter-legend-scenario-select"} onClick={selectScenarioType('')}>{'select scenario'}</DropdownItem>
                                    {
                                        labels.scenario.map((e, i) => <DropdownItem key={"model-parameter-legend-scenario-" + i.toString()} onClick={selectScenarioType(e)}>{e}</DropdownItem>)
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            /////////////////////////////////////////////
                        }
                    </div>
                }
                <br />
                { labels["eventsForRightAxis"].length>0 &&
                    <div key= "model-parameter-legend-events-list" style={{display: 'inline-block'}}> 
                    <h5 style={{float:'left'}}>Incremental Events:&nbsp;&nbsp;</h5>
                    {   
                        labels.eventsForRightAxis.map((e, i)=> {
                            let categoryObj = eventCategoryObj(DisplayedScenario)
                            // console.log(categoryObj)
                            var eventIndex = categoryObj.categories.findIndex(x=>x==e)
                            if (eventIndex < 0) {
                                console.log("Error:" + eventIndex + ", " + e);
                                eventIndex = 0;
                                return <></>
                            }
                            
                            return (<div key={"model-incre-event-label-" + i} style={{float:'left'}}>
                                <input type="checkbox" name={e}
                                    onChange={toggleEvent} checked={EventVisibilities.includes(e)}
                                    ref={el => inputRef.current["model-incre-event-label-" + i] = el} 
                                   />
                                <label style={{backgroundColor:colorsRightAxis[eventIndex]}}>{e}</label> 
                                &nbsp;&nbsp;
                            </div>)
                        })
                    }
                    </div>
                }
                <br />
                { labels["referenceAreas"].length>0 &&
                    <div key= "model-parameter-legend-events-significant" style={{display: 'inline-block'}}> 
                    <h5 style={{float:'left'}}>Significant Events: &nbsp;&nbsp; &nbsp;</h5>
                    { 
                        labels.referenceAreas.map((e, i)=>{
                            // var eventIndex = EventCategories.findIndex(x=>x==e)
                            let categoryObj = eventCategoryObj(DisplayedScenario)
                            var eventIndex = categoryObj.categories.findIndex(x=>x==e)
                            if (eventIndex < 0) {
                                console.log("Error:" + eventIndex + ", " + e);
                                eventIndex = 0;
                                return <></>
                            }
                            return (<div key={"model-major-event-label-" + i} style={{float:'left'}}>
                                <input type="checkbox" name={e} checked={EventVisibilities.includes(e)}
                                    onChange={toggleEvent} ref={el => inputRef.current["model-major-event-label-" + i] = el} />
                                <label style={{backgroundColor:colorsRightAxis[eventIndex]}}>{e}</label> 
                            </div>)
                        })
                    }
                    </div>
                }
            </>)
    }

    return <div key="ModelComparison" className="w-400 h-100" width={width} height={height}>
    <h1 style={{textAlign: 'center'}}>Model Parameter Inference</h1> 
    <VisLegends key= "model-parameter-legend" labelmap={legendEntries}></VisLegends>
    {tuples.map((item) =>{return visOneCategory(legendEntries, item, width, height, getScenarioName)})}
    </div>;
};


const ModelComparison = withResizeDetector(AdaptiveModelComparison);

export default ModelComparison
