import React, { useLayoutEffect, useRef } from "react";
import * as d3 from "d3";
import "./LineChart.css";
import { lineChartConstants } from "../Constants";
import { min, max } from "lodash";
import * as utilities from './Utilities'

/**
 * scenarioData: [{isSimulated:Yes, data:[num, num, ...], name:""}]
**/

const LineChart = ({prewidth, preheight, scenarioDataFull, selectedScenario, 
                    dataVisibilityBasic, callbackfn }) => {
    const svgref = useRef(null);
    // console.log(scenarioDataFull)
   
    useLayoutEffect(() => {
        var svg = d3.select(svgref.current),
            margin = { top: 20, right: 5, bottom: 15, left: 60 },
            width  = +svg.attr("width") - margin.left - margin.right,
            height = +svg.attr("height") - margin.top - margin.bottom;

        svg.selectAll("*").remove();

        if (Object.keys(scenarioDataFull).length == 0 || selectedScenario.length ==0 || 
                dataVisibilityBasic.length == 0) 
                return;
        let scenarioName = utilities.getScenarioName(selectedScenario[0]);
        const scenarioData = scenarioDataFull[scenarioName];
        // console.log(selectedScenario)
        // console.log(scenarioData)

        var dataVisibility = [];
        scenarioData.map(oneScenario=>{
            Object.keys(oneScenario.data[0]).forEach((key)=>{
                if (key.includes(dataVisibilityBasic[0]))
                    dataVisibility.push(key);
            })
        })

        // console.log(dataVisibility)

        var parseDate = d3.timeParse("%Y-%m-%d");

        let lowerDates = scenarioData.map(item=>min(item.data.map(e=>e.timestr)));
        let maxDates = scenarioData.map(item=>max(item.data.map(e=>e.timestr)));
        var x = d3.scaleTime().range([0, width])
                  .domain([parseDate(min(lowerDates)), parseDate(max(maxDates))]);
        var lowerVal = null;
        var maxVal   = null;

        scenarioData.forEach((scenario, i) => {
            scenario.data.forEach(item=>{
                Object.keys(item).forEach((k)=>{
                    if (k.includes(dataVisibility[i])) {
                        lowerVal = (lowerVal==null)? item[k]:lowerVal;
                        maxVal   = (maxVal==null)? item[k]:maxVal;
                        lowerVal = (item[k]==undefined || item[k]==null || 
                                    lowerVal <= item[k])? lowerVal:item[k];
                        maxVal   = (item[k]==undefined || item[k]==null ||
                                    maxVal >= item[k])? maxVal:item[k];
                    }
                })
            })
        })

        var y = d3.scaleLinear().range([height, 0])
                  .domain([lowerVal, maxVal]).nice();
            
        var xAxis = d3.axisBottom(x),
            yAxis = d3.axisLeft(y);

        var LineChart = svg
                .append("g")
                .attr("class", "focus")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        LineChart.append("g").style("font", "16px sans-serif").style("color","#636363")
                .attr("class", "axis axis--x")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis.ticks(6).tickFormat(d3.timeFormat("%Y-%m-%d")));

        LineChart.append("g")
                 .attr("class", "axis axis--y")
                 .call(yAxis);
        
        var Scatter = svg
                .append("g")
                .attr("class", "focus")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        

        var line = function(entryKey) {
            return d3
                .line()
                .x(function(d) {return x(parseDate(d.timestr));})
                .y(function(d) {
                    if (d[entryKey]==undefined || d[entryKey] == null)
                        console.log(d[entryKey])
                    return y(d[entryKey]);
                });
        }

        scenarioData.forEach((d, i) => {
            let lineClass = "line " + (d.isSimulated ? "model " : "observed ") + "-" +d.name;
            LineChart.append("path")
                .datum(d.data)
                .attr("class", lineClass)
                .attr("d", line(dataVisibility[i]))
                .on("mouseover", function() {
                    d3.select(this).style("cursor", "pointer");
                })
                .on("mouseout", function() {
                    d3.select(this).style("cursor", "move");
                })
                .on("click", (function(event, d) {
                      console.log("temporarily disabled due to d3.js upgrade to v7 and d3.event have been redesigned since v6")
                      // console.log(d)
                   let xDate = x.invert(d3.pointer(event)[0]);
//                    // console.log("Clicked ID: " + d3.event.target);
//                    callbackfn(xDate, i, d3.mouse(this));
                }))
                .style("stroke", (d.isSimulated)? "#5ab4ac":"#d8b365")
                .style("fill", lineChartConstants.line.fill)
                .style("stroke-width", lineChartConstants.line.strokeWidth);

            if (d.isSimulated) {
                let tag= dataVisibility[i];
                let glyph = [{
                    shape: lineChartConstants.glyph.shape,
                    size: lineChartConstants.glyph.size,
                    date: d.data[0].timestr,
                    num_cases: d.data[0][tag]
                }];
                console.log(glyph)
                let diamondClass = "diamond-" + i;
                Scatter.selectAll(".dots")
                    .data(glyph)
                    .enter()
                    .append("path")
                    .attr("class", diamondClass)
                    .attr("d", function(d) {
                        return d3.symbol().type(d.shape).size(d.size)();
                    })
                    .attr("transform", function(e) {
                        return "translate(" + x(parseDate(e.date)) + "," + y(e.num_cases) + ")";
                    })
                    .style("fill", lineChartConstants.glyph.fill);
            }
        });

        
    });

    return <svg width={prewidth} height={preheight} className='timechart-svg' ref={svgref} />;
}

export default React.memo(LineChart);
