import React, {useContext, useEffect, useRef} from 'react';
import * as d3 from "d3";
import {select, pointer} from "d3-selection";
import "./MultiLineChart.css";
import { lineChartConstants } from "../Constants";
// import {GlobalContext} from "../GlobalContext";
import {getColorByDataSourceDisplayName} from "./Utils";

const MultiLineChart = ({ category, dataCategoryList, plotData, callbackfn }) => {
  // const {ModelOptions} = useContext(GlobalContext);
  const svgref = useRef(null);

  useEffect(() => {
    if (plotData === null || plotData === undefined || plotData.length == 0 || svgref.current === null) {return;}

    //filter out related data
    let ylists = plotData.map(function (timeSeries) {
      let dataCategory = (timeSeries.type === 'Observed') ? dataCategoryList[1] : dataCategoryList[0];
      return timeSeries.data.map(d => d[dataCategory]).filter(d => d !== null && d !== undefined);
    });
    let ylist = [].concat.apply([], ylists);

    if (ylist === null || ylist === undefined || ylist.length == 0) {return;}

    let yDataMax = d3.max(ylist);
    let yDataMin = d3.min(ylist);

    let dates = plotData.map(function (timeSeries) {
      return timeSeries.data.map(d => d.date);
    })[0];

    const svg = d3.select(svgref.current),
        margin = { top: 20, right: 60, bottom: 40, left: 60 },
        width = +svg.attr("width") - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom;

    svg.selectAll("*").remove();

    var x = d3.scaleTime().range([0, width]).domain(d3.extent(dates, (d) => { return d; })),
        y = d3.scaleLinear().range([height, 0]).domain([yDataMin, yDataMax]).interpolate(d3.interpolateRound);


    let focus = svg
        .append("g")
        // .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    focus
        .append("g")
        // .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .style("font", "10px sans-serif")
        .call(d3.axisBottom(x).ticks(6));

    focus
        .append("g")
        // .attr("class", "axis axis--y")
        // .attr("transform", "translate(0," + height + ")")
        .call(d3.axisLeft(y));

    plotData.forEach((timeSeries, i) => {
      // let lineClass = "line ";
      let lineColor = getColorByDataSourceDisplayName(timeSeries.type);
      let dataCategory = (timeSeries.type === 'Observed') ? dataCategoryList[1] : dataCategoryList[0];
      let extractCoor = d3.line()
          .x(function(d) { return x(d.date);})
          .y(function(d) { return y(d[dataCategory]);});
      focus.append("path")
          .datum(timeSeries.data)
          // .attr("class", lineClass)
          .on("mouseover", function() {
            d3.select(this).style("cursor", "pointer");
          })
          .on("mouseout", function() {
            d3.select(this).style("cursor", "move");
            callbackfn({'date':null, 'x': 0, 'y': 0, 'visibility': 'hidden'});
          })
          .on("click", (event)=> {
            let xDate = x.invert(pointer(event)[0]);
            callbackfn({'date':xDate, 'x': event.clientX, 'y':event.clientY, 'visibility': 'visible'});
          })
          .attr("stroke", lineColor)
          .attr("stroke-width", 3)
          .attr("fill", "none")
          .attr("d", extractCoor);
    });

    //draw line
    let line =  svg.append("line").attr("y1", margin.top-10).attr("y2", height-margin.bottom).attr("stroke", "rgba(0,0,0,0.2)").style("pointer-events","none");
  }, [plotData, svgref.current]);

  return <svg width={lineChartConstants.svg.width}
              height={lineChartConstants.svg.height}
              viewBox={lineChartConstants.svg.viewBox}
              className='timechart-svg'
              ref={svgref} />;
};

export {MultiLineChart};