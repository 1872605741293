import React, { Component,useContext, useState } from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'
import {colorsLeftAxis, colorsRightAxis, colorReferenceArea, scenarioGlyphs} from './model_comparison_utilies'


class LinePlotDualYAxis extends Component {
    constructor(props){
       super(props)
       this.createPlot = this.createPlot.bind(this);
       this.mouseDownHandler = this.mouseDownHandler.bind(this);
       this.mouseUpHandler = this.mouseUpHandler.bind(this);
       this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    }

    componentDidMount() {
       this.createPlot()
    }

    componentDidUpdate() { 
        // console.log("test")
        select(this.node).selectAll('svg').remove();
        this.createPlot()
    }

    mouseDownHandler(e){    }

    mouseMoveHandler(e){    }

    mouseUpHandler(){    }

    getCanvasWidth() {return this.props.size[0] - this.props.margin.left - this.props.margin.right;}
    getCanvasHeight() {return this.props.size[1] - this.props.margin.top - this.props.margin.bottom;}

    getLineNamesForLeftAxis() {
        var tupleScenarios = []
        // console.log(this.props.data.leftAxis)
        Object.keys(this.props.data.leftAxis[0].value[0]).forEach(key=>{
            if( key != 'dayIndex') {
                tupleScenarios.push(key)
            }
        })
        return tupleScenarios;
    }

    getXRange(){
        const data = this.props.data.leftAxis;
        var xmin = data[0].value[0].dayIndex;
        var xmax = xmin;
        data.forEach((entry)=>{
            entry.value.forEach((tuple)=>{
                if (xmin > tuple.dayIndex) {
                    console.log([xmin, tuple.dayIndex]);
                }
                xmin = Math.min(tuple.dayIndex, xmin);
                xmax = Math.max(tuple.dayIndex,xmax);
            })
        })
        return [xmin, xmax];
    }

    getYRangeAxisLeft(){
        const data = this.props.data.leftAxis;
        const tupleScenarios = this.getLineNamesForLeftAxis();
        var ymin = undefined;
        var ymax = undefined;
        data.forEach((entry)=>{
            entry.value.forEach((tuple)=>{
                tupleScenarios.forEach((key)=>{
                    if(tuple[key] != undefined) {
                        ymin = (ymin==undefined)? tuple[key]:Math.min(tuple[key], ymin);
                        ymax = (ymax==undefined)? tuple[key]:Math.max(tuple[key], ymax);
                    }
                })
            })
        })
        return [ymin-ymin*0.1, ymax*1.1]
    }

    getYAxisLeft() {
        const height = this.getCanvasHeight()
        const yrange = this.getYRangeAxisLeft()
        return d3.scaleLinear()
            .domain(yrange)
            .range([height,0]).interpolate(d3.interpolateRound);
    }

    getYAxisRight() {
        const height = this.getCanvasHeight()
        const data = this.props.data.rightAxis;
        var ymin = 2;
        var ymax = 1;
        data.forEach((oneParam) => {
            oneParam.value.forEach((oneScenario) => {
                const sname = Object.keys(oneScenario)[0];
                const c = oneScenario[sname].multiplier
                ymin = (c< ymin)? c:ymin;
                ymax = (c > ymax)? c:ymax;
            })
        })
        return d3.scaleLinear()
            .domain([ymin*0.9999, ymax*1.0001])
            .range([height,0]).interpolate(d3.interpolateRound);
    }


    getXAxis(){
        const width  = this.getCanvasWidth()
        const xrange = this.getXRange()
        return d3.scaleTime()
            .domain(xrange)
            .range([0, width]);
    }
    
    getEventCategories(scenarioName) {
        console.log(scenarioName)
        console.log(this.props.eventCategoryLookup)
        let tmp = scenarioName.split('(')[1]
        let tmp2 = tmp.split(')')[0]
        let tokens = tmp2.split('-')
        let fips = tokens[0]
        let policy = tokens[1]
        let i = this.props.eventCategoryLookup.findIndex(x => x.dataset == policy && x.fips.includes(fips))
        return this.props.eventCategoryLookup[i]['categories']
    }

    createPlot() {
        const node = this.node;
        const width = this.getCanvasWidth()
        const height = this.getCanvasHeight()
        var margin = this.props.margin

        const yAxisLeft = this.getYAxisLeft()
        const xAxis= this.getXAxis()
        
        var dualplot =  select(node)
            .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
            .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        const yrange = this.getYRangeAxisLeft()
        dualplot.append("g").style("font", "16px sans-serif").style("color","#636363")
                            .attr("transform", "translate(0," + yAxisLeft(yrange[0]) + ")")
                            .call(d3.axisBottom(xAxis)
                            .ticks(5).tickFormat(d3.timeFormat("%Y-%m-%d")));
        
        const xrange = this.getXRange()
        dualplot.append("g").style("font", "10px sans-serif").style("stroke",colorsLeftAxis[0])
                .call(d3.axisLeft(yAxisLeft).ticks(3));
        
        const tupleScenarios = this.getLineNamesForLeftAxis();
        this.props.data.leftAxis.forEach((oneParam)=>{
            tupleScenarios.forEach( (key, i)=>{
                var dataset = []
                oneParam.value.forEach(item=>{
                    if(item[key] !=undefined)
                        // console.log(item.dayIndex)
                        dataset.push({dayIndex:item.dayIndex, value:item[key]})
                })
                // console.log(dataset)
                var valuelineleft = d3.line()
                              .x(function(d) { return xAxis(d.dayIndex); })
                              .y(function(d) { return yAxisLeft(d.value); })
                              .curve(d3.curveMonotoneX) 
                dualplot.append("path")
                        .datum(dataset) 
                        .attr("stroke-width", 3)
                        .attr("stroke", colorsLeftAxis[1])
                        .attr("fill", "none" )
                        .attr("d", valuelineleft); 

                if(this.props.data.referenceAreas != undefined) {
                    // console.log(this.props.data.referenceAreas)
                    this.props.data.referenceAreas.forEach((oneEvent, i)=>{
                        // console.log(oneEvent)
                        // console.log(this.props.shownEvents)
                        if(this.props.shownEvents.includes(oneEvent.name)) {
                            Object.keys(oneEvent.value).forEach((scenarioName, i)=>{
                                // console.log([scenarioName, key])
                                // console.log(this.props.eventCategoryLookup)
                                // console.log(oneEvent.name)
                                // console.log(eventColorId)
                                if (scenarioName == key) {
                                    let eventColorId = this.getEventCategories(scenarioName).findIndex(x=>x==oneEvent.name);
                                    const interval = oneEvent.value[scenarioName];
                                    const startTime = Date.parse(interval.begin)
                                    const endTime = Date.parse(interval.end)
                                    const segments = dataset.filter(x=>(x.dayIndex>=startTime && x.dayIndex<=endTime))
                                    // console.log(segments)
                                    dualplot.append("path")
                                        .datum(segments) 
                                        .attr("stroke-width", 3)
                                        .attr("stroke", colorsRightAxis[eventColorId])
                                        .attr("fill", "none" )
                                        .attr("d", valuelineleft); 
                                }
                            })
                        }
                    })
                }

                var dataset2 = []
                const startIndex = Math.floor(Math.random() * Math.floor(20));
                dataset.forEach((item, j)=>{ 
                    if((j-startIndex)%20==0) {
                        dataset2.push(item)
                    }
                })
                if(scenarioGlyphs[i] == 'dot'){
                    dualplot.selectAll(".dot") // Uses the enter().append() method
                            .data(dataset2) 
                        .enter().append("circle")
                            .attr("class", "dot") // Assign a class for styling
                            .attr("cx", function(d, i) { return xAxis(d.dayIndex) })
                            .attr("cy", function(d) { return yAxisLeft(d.value) })
                            .attr("r", 4)
                            .attr("fill", colorsLeftAxis[1]);
                }
                else if(scenarioGlyphs[i] == 'rect') {
                    dualplot.selectAll(".rect") // Uses the enter().append() method
                        .data(dataset2) 
                    .enter().append("rect")
                        .attr("class", "rect") // Assign a class for styling
                        .attr("x", function(d, i) { return xAxis(d.dayIndex)-4 })
                        .attr("y", function(d) { return yAxisLeft(d.value)-4 })
                        .attr("width", 8).attr("height", 8)
                        .attr("fill", colorsLeftAxis[1]);
                }
                else if(scenarioGlyphs[i] == 'triangle'){
                    var sym =  d3.symbol().type(d3.symbolTriangle).size(50); 
                    dualplot.selectAll(".triangle-box") // Uses the enter().append() method
                            .data(dataset2) 
                        .enter().append("path")
                            .attr("d", sym) // Assign a class for styling
                            .attr("fill", colorsLeftAxis[1])
                            .attr("transform", function(d) {return "translate("+xAxis(d.dayIndex)+ ","+ yAxisLeft(d.value)+")"}); 
                }
            })
        })

        if(this.props.data.rightAxis != undefined) {
            const yAxisRight = this.getYAxisRight()
            
            var valuelineRight = d3.line()
                                   .x(function(d) { return xAxis(d.dayIndex);})
                                   .y(function(d) { return yAxisRight(d.value);});
            this.props.data.rightAxis.forEach((oneEvent)=>{
                if (!this.props.shownEvents.includes(oneEvent.name))
                    return
                // const eventColorId = this.props.rightAxisEvents.findIndex(x=>x==oneEvent.name);
                // const eventColorId = this.props.eventCategoryLookup.findIndex(x=>x==oneEvent.name);
                
                oneEvent.value.forEach((oneScenario, i)=>{
                    const sname = Object.keys(oneScenario)[0];
                    const eventColorId = this.getEventCategories(sname).findIndex(x=>x==oneEvent.name);
                    oneScenario[sname]['timerange'].map((tmp, i) =>{
                        if (i%2 ==0) {
                            var bd = Date.parse(oneScenario[sname]['timerange'][i]);
                            var ed = Date.parse(oneScenario[sname]['timerange'][i+1]);
                            var dataset = [{dayIndex:bd, value:oneScenario[sname].multiplier}, 
                                        {dayIndex:ed, value:oneScenario[sname].multiplier}]
                            dualplot.append("path")
                                    .datum(dataset) 
                                    .attr("stroke-width", 3)
                                    .attr("stroke", colorsRightAxis[eventColorId])
                                    .attr("fill", "none" )
                                    .attr("d", valuelineRight); 

                            const offset1 = Math.floor(Math.random() * Math.floor(ed-bd));
                            const offset2 = Math.floor(Math.random() * Math.floor(ed-bd));
                            var dataset2 = [
                                {dayIndex:bd+offset1, value:oneScenario[sname].multiplier}, 
                                {dayIndex:bd+offset2, value:oneScenario[sname].multiplier}
                            ]
                            if(scenarioGlyphs[i] == 'dot'){
                                dualplot.selectAll(".dotRightAxis") // Uses the enter().append() method
                                        .data(dataset2) 
                                    .enter().append("circle")
                                        .attr("class", "dot") // Assign a class for styling
                                        .attr("cx", function(d, i) { return xAxis(d.dayIndex) })
                                        .attr("cy", function(d) { return yAxisRight(d.value) })
                                        .attr("r", 4)
                                        .attr("fill", colorsRightAxis[eventColorId])
                            }
                            else if(scenarioGlyphs[i] == 'rect'){
                                dualplot.selectAll(".rectRightAxis") // Uses the enter().append() method
                                    .data(dataset2) 
                                .enter().append("rect")
                                    .attr("class", "rect") // Assign a class for styling
                                    .attr("x", function(d, i) { return xAxis(d.dayIndex)-4 })
                                    .attr("y", function(d) { return yAxisRight(d.value)-4 })
                                    .attr("width", 8).attr("height", 8)
                                    .attr("fill", colorsRightAxis[eventColorId])
                            }
                            else if(scenarioGlyphs[i] == 'triangle'){
                                var sym =  d3.symbol().type(d3.symbolTriangle).size(50); 
                                dualplot.selectAll(".triangle-box-RightAxis") // Uses the enter().append() method
                                        .data(dataset2) 
                                    .enter().append("path")
                                        .attr("d", sym) // Assign a class for styling
                                        .attr("fill", colorsRightAxis[eventColorId])
                                        .attr("transform", function(d) {return "translate("+xAxis(d.dayIndex)+ ","+ yAxisRight(d.value)+")"}); 
                            }
                        }
                    })
                    
                })
            })
            // console.log(xrange)
            dualplot.append("g").style("font", "10px sans-serif").style("stroke",colorsRightAxis[0])
                .attr("transform", "translate(" + xAxis(xrange[1]) + " ,0)")	
                .call(d3.axisRight(yAxisRight).ticks(3));
       }
    }

    render() {
       return <svg ref={node => this.node = node} width={this.props.size[0]} height={this.props.size[1]} 
                onMouseMove={this.mouseMoveHandler} onMouseDown={this.mouseDownHandler} onMouseUp={this.mouseUpHandler}/>
    }
 }

 export default LinePlotDualYAxis;