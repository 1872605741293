
function groupDataByAges(){
    return {"total": 0, "0-9": 0, "10-19": 0, "20-29": 0, "30-39": 0, "40-49": 0, "50-59": 0, "60-69": 0, "70-79": 0, "80+": 0};
  }
  
  function initExportedTimePoint(){
    return {
      'time': 0,
      'exposedCurrentData':{
          'susceptible' : groupDataByAges(), 
          'exposed': groupDataByAges(),
          'infectious' : groupDataByAges(), 
          'severe' : groupDataByAges(), 
          'critical' : groupDataByAges(),
          'overflow' : groupDataByAges(), 
          'weeklyFatality' : groupDataByAges() 
      },
      'exposedCumulativeData':{
          'recovered' : groupDataByAges(), 
          'hospitalized' : groupDataByAges(),
          'critical' : groupDataByAges(), 
          'fatality' : groupDataByAges()
      }
    }
  }
  
  export function initDataFormat(){
    return {
      trajectory: {lower:[initExportedTimePoint()], middle:[initExportedTimePoint()], upper:[initExportedTimePoint()], percentile: {}}, 
      R0: {mean:[{t:0, y:0}], lower:[{t:0, y:0}], upper:[{t:0, y:0}]}
    };
  }
  
  export function initAgeGroups(){
    return [{'ageGroup:':"0-9", 'population':513836}, {'ageGroup:':"10-19", 'population':553818}, {'ageGroup:':"20-29", 'population':541565}, {'ageGroup:':"30-39", 'population':529992},
    {'ageGroup:':"40-49", 'population':467185}, {'ageGroup:':"50-59", 'population':472564}, {'ageGroup:':"60-69", 'population':446186},
    {'ageGroup:':"70-79", 'population':279597}, {'ageGroup:':"80+", 'population':152228}];
  }
  