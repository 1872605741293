import React, { Component,useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext';
import { colorsRightAxis } from '../model_comparison/model_comparison_utilies';
import BarChart from './BarChart.js'
import EventCategorySelectionPanel from './EventCategorySelectionPanel'
import EventsRegionOptionPanel from './EventsRegionOptionPanel'
import {interventionEventsModule} from '../API'
import {isSameRegion} from "../RegionConcepts/RegionUtils";
// import { getRegionId, getRegionIDofOneCountry } from '../FunctionsCommon';

var lodash_1 = require("lodash");

const colorsMitigations = ["#f7fcb9","#d9f0a3","#addd8e","#78c679","#41ab5d","#238443","#006837","#004529"];//"#ffffe5",

const colorsAcceleration = ["#fdae6b", "#fd8d3c", "#f16913", "#d94801", "#a63603", "#7f2704"];//"#fee6ce", "#fdd0a2", 

const defaultEventColor = "#a6cee3"

function compare(a, b) {
    if (a.rate < b.rate) {
      return -1;
    }
    if (a.rate>b.rate) {
      return 1;
    }
    // a must be equal to b
    return 0;
}

const EventBarChart = ({width, height}) => {
    const { MitigationData, setMitigationData, AccelerationData, setAccelerationData, EventCategories, ObservationDataModeling,
            SelectedRegionOfEventView, setSelectedRegionOfEventView, PolicyDataset, setPolicyDataset, getCountryISOCode, PickedCountry
    } = useContext(GlobalContext);
    
    const [ContextMenu, setContextMenu] = useState({visible:false, categoryId:-1});
    const [plotData, setPlotData] = useState([])

    //a unique id for a region wrt a country or one of it regions
    const updateInterventionPlan = (selectedRegion, selectedPolicyDataset) => {
        console.log(selectedRegion)
        if (isSameRegion(selectedRegion, SelectedRegionOfEventView) && PolicyDataset == selectedPolicyDataset) return;
        let mindex = MitigationData.findIndex(x=>isSameRegion(x.region, selectedRegion) && x.dataset==selectedPolicyDataset)
        let aindex = AccelerationData.findIndex(x=>isSameRegion(x.region, selectedRegion) && x.dataset==selectedPolicyDataset);
        if (mindex < 0 || aindex < 0) {
            console.log('fetch for new data')
            fetch(interventionEventsModule,  {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({'region': selectedRegion, 'policyDataset':selectedPolicyDataset})})
            .then(response => response.json())
            .then((jsonData) => {
                console.log(jsonData)
                if (AccelerationData[0].region.length == 0) {
                    setAccelerationData([{region: selectedRegion, events:jsonData.excerbation, dataset:selectedPolicyDataset}]);
                } else if(aindex<0) {
                    setAccelerationData(prevState => 
                            [... prevState, {region: selectedRegion, events:jsonData.excerbation, dataset:selectedPolicyDataset}]);
                }
                if (MitigationData[0].region.length == 0) {
                    setMitigationData([{region: selectedRegion, events:jsonData.mitigation, dataset:selectedPolicyDataset}]);
                }else if(mindex<0){
                    setMitigationData(prevState => 
                            [... prevState, {region: selectedRegion, events:jsonData.mitigation, dataset:selectedPolicyDataset}]);
                }
                setSelectedRegionOfEventView(selectedRegion);
                if (PolicyDataset != selectedPolicyDataset) {
                    setPolicyDataset(selectedPolicyDataset)
                }
            })
            .catch((error) => {
              console.error(error);
            })
        }
        else {
            setSelectedRegionOfEventView(selectedRegion);
            if (PolicyDataset != selectedPolicyDataset) {
                setPolicyDataset(selectedPolicyDataset)
            }
        }
    }
    const showEventCategory=(entry =>{
        // console.log(entry)
        var eventCategory = ""
        if (entry.eventTag == "mitigation") {
            let mindex = MitigationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset)
            let clone = lodash_1.cloneDeep(MitigationData);
            let eventData = clone[mindex].events[entry.eventTagId];
            console.log(eventData)
            if (Object.keys(eventData).includes("category") &&
                eventData['category'] != undefined) {

                eventCategory = eventData['category'];
            }
        }
        else {
            let aindex = AccelerationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset);
            // console.log(aindex)
            // console.log(AccelerationData)
            // console.log(entry)
            let clone = lodash_1.cloneDeep(AccelerationData);
            let eventData = clone[aindex].events[entry.eventTagId];
            console.log(eventData)
            if (Object.keys(eventData).includes("category") &&
                eventData['category'] != undefined) {
                    
                eventCategory = eventData['category'];
            }
        }
        let arrs = EventCategories.filter(x=>x.fips==SelectedRegionOfEventView && x.dataset == PolicyDataset)
        console.log(arrs)
        entry['categoryId'] = arrs[0]['categories'].findIndex(x=>x==eventCategory)
        console.log(entry)
        setContextMenu(entry);
    });

    /*update an event's event category tag*/
    const selectEventCategory = (entry => {
        console.log(entry)
        // setContextMenu(entry);
        let mindex = MitigationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset)
        let mitigationCopy = MitigationData[mindex].events;
        let aindex = AccelerationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset)
        // let accelerationCopy = AccelerationData[aindex].events;
        let mlength = mitigationCopy.length
        // let alength = AccelerationData.length
        if (ContextMenu.eventIndex >= mlength) {//Acceleration
            let i = ContextMenu.eventIndex-mlength
            let clone = lodash_1.cloneDeep(AccelerationData);
            let eventData = clone[aindex].events[i];
            // console.log(eventData)
            eventData['category'] = entry
            setAccelerationData(clone)
            console.log(AccelerationData)
        }
        else {
            let clone = lodash_1.cloneDeep(MitigationData);
            let eventData = clone[aindex].events[ContextMenu.eventIndex];
            eventData['category'] = entry;
            console.log(eventData)
            setMitigationData(clone)
        }
        // setRedrawBarChart(true)
    });

    /*update an event's time interval*/
    const handleEventChange = (entry => {
        // console.log("handleEventChange");
        // console.log(entry);
        if (entry.changedEventTag == "mitigation") {
            let mindex = MitigationData.findIndex(x=>isSameRegion(x.region, SelectedRegionOfEventView) & x.dataset==PolicyDataset)
            let clone = lodash_1.cloneDeep(MitigationData);
            let eventData = clone[mindex].events[entry.changedEventId];
            if (!('timeRange' in eventData)) {
                eventData['timeRange'] = {};
            }
            eventData['timeRange']['begin'] = entry.dateFrom.toISOString().slice(0,10);
            eventData['timeRange']['end'] = entry.dateTo.toISOString().slice(0,10);
            setMitigationData(clone);
        }
        else {
            let aindex = AccelerationData.findIndex(x=>isSameRegion(x.region, SelectedRegionOfEventView))
            let clone = lodash_1.cloneDeep(AccelerationData);
            let eventData = clone[aindex].events[entry.changedEventId];
            // console.log(aindex)
            // console.log(clone[aindex])
            // console.log(entry);
            // console.log(eventData)
            if (!('timeRange' in eventData)) {
                eventData['timeRange'] = {};
            }
            eventData['timeRange']['begin'] = entry.dateFrom.toISOString().slice(0,10);
            eventData['timeRange']['end'] = entry.dateTo.toISOString().slice(0,10);
            setAccelerationData(clone);
        }
    })

    useEffect(() => {
        var isGetMitigationData = !(MitigationData==undefined || MitigationData.length < 1 || MitigationData[0].region.length==0);
        var isGetAccelerationData = !(AccelerationData==undefined || AccelerationData.length < 1 || AccelerationData[0].region.length==0);
        if (SelectedRegionOfEventView == undefined) return;
        if (!(isGetAccelerationData && isGetMitigationData)) return;
        var mitigation = [];
        var mitigationNames = [];
        var uniqueMitigationNames = [];
        var acceleration = [];
        var accelerationNames = [];
        var uniqueAccelerationNames = [];

        if(isGetMitigationData) {
            let mindex = MitigationData.findIndex(x=>isSameRegion(x.region, SelectedRegionOfEventView) && x.dataset==PolicyDataset);
            mitigation = lodash_1.cloneDeep(MitigationData[mindex].events).sort(compare);
            mitigation.forEach(function (_a, index){
                _a["rate"] = (_a.transmissionReduction.begin + _a.transmissionReduction.end)/2;
            });
            mitigationNames = mitigation.map(function (_a,i){return _a.name});
            uniqueMitigationNames = mitigationNames.filter((item, index)=>mitigationNames.indexOf(item)==index);
        }

        if (isGetAccelerationData) {
            let mindex = AccelerationData.findIndex(x=>isSameRegion(x.region, SelectedRegionOfEventView) && x.dataset==PolicyDataset);
            acceleration = lodash_1.cloneDeep(AccelerationData[mindex].events).sort(compare);
            acceleration.forEach(function (_a, index){
                _a["rate"] =  index;
            });
            accelerationNames = acceleration.map(function (_a,i){return _a.name});
            uniqueAccelerationNames = accelerationNames.filter((item, index)=>accelerationNames.indexOf(item)==index);
        }

        var totalEvents = (isGetMitigationData? uniqueMitigationNames.length:1) + (isGetAccelerationData? uniqueAccelerationNames.length:1);
        var barWidth = 10;
        var graphHeight = barWidth*totalEvents;
        if (graphHeight > height*0.99) {
            graphHeight = Math.floor(height*0.99);
            barWidth = Math.floor(graphHeight/totalEvents)
        }
        var shrinkedBarWidth= barWidth*0.9;
        if (isGetMitigationData) {
            mitigation.forEach(function (item, index){
                var id = uniqueMitigationNames.indexOf(item.name)
                item["ycoor"] = id * barWidth + barWidth/2;
                item['ycoorMin'] = Math.max(0, Math.min(item.ycoor-shrinkedBarWidth/2, graphHeight));
                item['ycoorMax'] = Math.max(0, Math.min(item.ycoor+shrinkedBarWidth/2, graphHeight));
            });
            mitigation.forEach(function (_a, index) {
                var color = defaultEventColor
                if (("category" in _a)) {
                    let arr = EventCategories.filter(x=>x.dataset == PolicyDataset && isSameRegion(x.region, SelectedRegionOfEventView))
                    let colorId = -1
                    if (arr.length > 0)
                        colorId = arr[0]['categories'].findIndex( x => x==_a["category"])
                    if (colorId >= 0)
                        color = colorsRightAxis[colorId]
                }
                _a["color"] = color
            });
        }
        if (isGetAccelerationData) {
            acceleration.forEach(function (item, index){
                const id = uniqueAccelerationNames.indexOf(item.name)
                item["ycoor"] =  -id*barWidth - barWidth;
                item['ycoorMin']  = Math.max(-100, Math.min(item.ycoor-shrinkedBarWidth/2, 0));
                item['ycoorMax']  = Math.max(-100, Math.min(item.ycoor+shrinkedBarWidth/2, 0));
            });
            acceleration.forEach(function (_a, index){
                var color = defaultEventColor
                if (("category" in _a)){
                    let colorId = -1
                    let arr = EventCategories.filter(x=>x.dataset == PolicyDataset && isSameRegion(x.region, SelectedRegionOfEventView))
                    if (arr.length>0)
                        colorId = arr[0]['categories'].findIndex(x => x==_a["category"])
                    if (colorId >= 0)
                        color = colorsRightAxis[colorId]
                }
                _a["color"] = color
            });
        }

        var plots = mitigation.concat(acceleration).map(function (_a, i) {
            var item ={};
            item['name']      = _a.name;
            item['ycoorMin']  = _a.ycoorMin;
            item['ycoorMax']  = _a.ycoorMax;
            item['beginTime'] = new Date(_a.timeRange.begin);
            var tmp = _a.timeRange.end;
            item['endTime']   = (tmp == "")? new Date() : new Date(tmp);
            item['color']     = _a.color;
            item['key']       = 'event'+i.toString();
            item['tag']       = (i < mitigation.length)? 'mitigation':'acceleration';
            item['tagId']     = (i < mitigation.length)? i:(i-mitigation.length);
            return item;
        });
        setPlotData(plots);
    }, [MitigationData, AccelerationData, PolicyDataset, EventCategories]);

    return (
        <div key="EventsBarChart" >
            { plotData.length > 0 &&
                <BarChart key="Event-Bars" data={plotData}
                    setContextMenuVisibility={showEventCategory}
                    size={[width,  height]}
                    margin ={{top: 20, right: 20, bottom: 20, left: 30}}
                    updateEventData = {handleEventChange}/>
            }
            { ContextMenu.visible==1 && 
                <div style={{position:'absolute',
                             top: ContextMenu.top.toString()+"px", 
                             left: (ContextMenu.left+10).toString()+"px", 
                             backgroundColor:"#f0f0f0",
                            //  height:"200px",
                             width:"170px",
                             }}>

                    <EventCategorySelectionPanel key="EventCategorySelectionPanel"
                        handleEventCategorySelection={selectEventCategory}
                        defaultVal={ContextMenu.categoryId}/>
                </div>
            }
             <EventsRegionOptionPanel RegionList={ObservationDataModeling.map(x=>{return x.region.toString()})}
                    handleRegionSelection={updateInterventionPlan}></EventsRegionOptionPanel> 
        </div>);
}

export default EventBarChart
