import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../GlobalContext';
import { colorConstants, populationConstants } from '../Constants';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import {isSameRegion} from "../RegionConcepts/RegionUtils";
import {convertDate2Str} from "../DateTimeUtils";

function MapBottomView() {
    
    const {CountryInfo, StateInfo,CountyInfo, RegionOnMap, ObservationDataMap, MapDisplayDate, PickedCountry, PickedDataSource} = useContext(GlobalContext);
    const [countsState, setCountsState] = useState({cases: -1, deaths: -1})

    useEffect( () => {
        // console.log(ObservationDataMap)
        if (isSameRegion(ObservationDataMap['0']['region'], RegionOnMap)) {
            let countCases = 0, countDeaths = 0
            let data = ObservationDataMap[0]['data']
            let datestr = ObservationDataMap[0]['datestr']
            if (datestr == convertDate2Str(MapDisplayDate)) {
                if (RegionOnMap.length == 1) {
                    countCases = data['country']['observedCases']
                    countDeaths = data['country']['observedDeaths']
                } else if (RegionOnMap.length == 2) {
                    countCases = data['state'][0]['observedCases']
                    countDeaths = data['state'][0]['observedDeaths']
                } else {
                    countCases = data['county'][0]['observedCases']
                    countDeaths = data['county'][0]['observedDeaths']
                }
            }
            setCountsState({cases: countCases, deaths: countDeaths})
        }
    }, [RegionOnMap, ObservationDataMap])
    
    return (
       <div width={800}>
           <Label>Accumulated counts at {RegionOnMap.join(", ")}: &nbsp;</Label>
           <Label> {countsState.cases} cases,&nbsp; </Label>
           <Label> {countsState.deaths} deaths&nbsp;</Label>
       </div>
    );
}

export default MapBottomView;
