/*
This is the production file!
This file will be copied to API.js during docker build.
*/

/* Guizhen Wang
const IP1 = 'http://localhost:5000'
const IP2 =  'http://192.168.1.101:5001'
*/
const IP = 'https://api.panviz2.disc.ourcloud.ou.edu'

export const deftmodel = `${IP}/deftmodel`;
export const defaultparams = `${IP}/defaultparams`;
export const setparams = `${IP}/setparams`;
// export const seirmodel = `${IP2}/seirbyscenarios`;
// export const delphimodel = `${IP2}/loadDELPHIModel`;
// export const seirage = `${IP2}/ageDistributionByScenarios`;
export const covidModelingResults = `${IP}/covidModelingResults`;
export const covidObservations = `${IP}/loadCovidObservationData`;
export const covidObservationsForMap = `${IP}/loadCovidObservationDataForMap`;
// export const seirinfo = `${IP2}/loadScenarioInfo`;
export const stateDataIOModule = `${IP}/loadStateDemographicInfo`;
export const countyDataIOModule = `${IP}/loadCountyDemographicInfo`;
export const mitigationDataModule = `${IP}/loadMitigationData`;
export const acclerationDataModule = `${IP}/loadAccelerationData`;
export const simulationModule = `${IP}/runSimulation`;
export const interventionEventsModule = `${IP}/loadInterventionEvents`;
export const initBackend = `${IP}/initBackend`;