import React from 'react';
// import FeatureSelection from './FeatureSelection';
import { GlobalContextProvider } from './GlobalContext';
// import Layout from './Layout';
import PanVis from './PanVis';



function App() {
  return (
    // <GlobalContextProvider>
    //   <FeatureSelection/>
    // </GlobalContextProvider>
    // <Layout/>
    <GlobalContextProvider>
      <PanVis />
    </GlobalContextProvider>
  );
}

export default App;