import React, { Component, useContext, useState } from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'

class NodeLinkGraph extends Component {

    constructor(props) {
       super(props)
       this.state = { nodes: this.props.data.nodes,
        links: this.props.data.links
       }
    }

    componentDidMount() {
        this.create_node_link_graph()
    }

    componentWillUnmount() {
    }

    componentDidUpdate() {
      select(this.node).selectAll('svg').remove();
      this.create_node_link_graph()
    }
    
    create_node_link_graph() {
      if (this.state.nodes.length == 0) return
      const root_node = this.node;
      const width = this.props.size[0]
      const height = this.props.size[1]
      var svg = select(root_node).append("svg")
              .attr("width", width + this.props.margin.left + this.props.margin.right)
              .attr("height", height + this.props.margin.top + this.props.margin.bottom)
            .append("g")
              .attr("transform", "translate(" + this.props.margin.left + "," + this.props.margin.top + ")")
      var link = svg
              .selectAll("path")
              .data(this.state.links)
              .enter()
              .append("path")
                .style("stroke", "#aaa")
                .attr("class", "link")
                .attr("marker-end", d => {return "url(#line-end)"});
          
            // Initialize the nodes
      var elem_node = svg
              .selectAll("circle")
              .data(this.props.data.nodes)
              .enter()
              .append("circle")
                .attr("r", 20)
                .style("fill", "#69b3a2")

      var node_label = svg
              .selectAll("label")
              .data(this.props.data.nodes)
              .enter()
              .append("text")
                .text(d=>d.id)
                .style("font", "13px times")
                .attr("transform", "translate(-8,0)")

      // add arrows
      var arrow = svg.append("defs").selectAll("marker")
          .data(["line-end"])
          .enter()
          .append("marker")
            .attr("id", "line-end")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 0)
            .attr("refY", 0)
            .attr("markerUnits", "userSpaceOnUse")
            .attr("markerWidth", 12)
            .attr("markerHeight", 12)
            .attr("orient", "auto")
          .append("path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr('fill', "#333");


            // Let's list the force we wanna apply on the network
      var simulation = d3.forceSimulation(this.state.nodes)                 // Force algorithm is applied to data.nodes
                .force("link", d3.forceLink()                               // This force provides links between nodes
                      .id(function(d) { return d.id; })                     // This provide  the id of a node
                      .links(this.state.links)                                    // and this the list of links
                      )
                .force("charge", d3.forceManyBody().strength(-1000))         // This adds repulsion between nodes. Play with the -400 for the repulsion strength
                .force("center", d3.forceCenter(width / 2, height / 2))     // This force attracts nodes to the center of the svg area
                .on("end", ticked);
          
            // This function is run at each iteration of the force algorithm, updating the nodes position.
            function ticked() {
               //update link positions
              link.attr("d", positionLink1);
              link.filter(function(d){ return JSON.stringify(d.target) !== JSON.stringify(d.source); })
                .attr("d",positionLink2);

          
              elem_node
                  .attr("cx", function (d) { return d.x+6; })
                  .attr("cy", function(d) { return d.y-6; });

              node_label
                .attr("x", function (d) { return d.x; })
                .attr("y", function(d) { return d.y; });
            }

            //update link position
            function positionLink1(d) {
              var dx = d.target.x - d.source.x,
                  dy = d.target.y - d.source.y,
                  dr = Math.sqrt(dx * dx + dy * dy);
              return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
            }

            //update link end position
            function positionLink2(d) {
              // length of current path
              var pl = this.getTotalLength(),
                // radius of circle plus marker head
                r = 20+10, //16.97 is the "size" of the marker Math.sqrt(12**2 + 12 **2)
                // position close to where path intercepts circle 
                m = this.getPointAtLength(pl - r);          

              var dx = m.x - d.source.x,
                dy = m.y - d.source.y,
                dr = Math.sqrt(dx * dx + dy * dy);

              return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + m.x + "," + m.y;
            }
    }

    render() {
       return <svg ref={node => this.node = node} width={this.props.size[0]} height={this.props.size[1]} />
    }
}

export default NodeLinkGraph;
