import React, {useContext, useState, useRef} from 'react'
// import { GlobalContext } from '../GlobalContext';
// import {Button } from 'reactstrap';
import * as utilities from './Utilities';

const ScenarioOptionPanel = ({
        scenarioList,handleScenarioSelection, handleDataOptionSelection}) =>{
  
  const [indexOfCheckedScenarioRadio, setIndexOfCheckedScenarioRadio] = useState(-1);
  const [indexOfCheckedDataOptionRadio, setIndexOfCheckedDataOptionRadio] = useState('');
  const selectScenario = ((e)=>{
    setIndexOfCheckedScenarioRadio(e.target.value)
    let str = (e.target.value <0)?null:scenarioList[e.target.value]
    handleScenarioSelection(str)
  })
  const selectDataOption = ((e)=>{
        setIndexOfCheckedDataOptionRadio(e.target.value)
        handleDataOptionSelection(e.target.value)
  })
  const dataOptions = ['Death', 'Case', 'Hospitalization']
return (<>
        <label >Select scenario category:&nbsp;&nbsp;</label> 
        { scenarioList.length > 0 && 
                scenarioList.map((scenario, i)=>{
                        return  <label key={"Scenario-"+i}
                                style={{display:"inline-block", }}>
                                <input type="radio"
                                        checked={(i)==indexOfCheckedScenarioRadio}
                                        onChange={selectScenario}
                                        value={i}/>
                                {utilities.getScenarioName(scenario)}&nbsp;&nbsp;
                                </label>
                        })
        }   
        <br />
        <label >Select data type: &nbsp;&nbsp;</label> 
        { dataOptions.length > 0 && 
                dataOptions.map((option, i)=>{
                        return  <label key={"DataOptions-"+i}
                                style={{display:"inline-block", }}>
                                <input type="radio"
                                        checked={(option)==indexOfCheckedDataOptionRadio}
                                        onChange={selectDataOption}
                                        value={option}/>
                                {option}&nbsp;&nbsp;
                                </label>
                        })
        }               
    </>)
}

export default ScenarioOptionPanel;

