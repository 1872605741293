/**
 * Modeling view allows users to select modeling parameters and display modeling results.
 * **/
// import { rollup } from 'd3';
import React, {useContext, useState} from 'react';
import { GlobalContext} from './GlobalContext';

import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import {isSameRegion, isSubRegion} from "./RegionConcepts/RegionUtils";
import './css_config/ReactDatePickerStyles.css'
import {ModelOptions} from "./Constants";
/*
* select regions, time, covid data, and model
*/
function ModelCovidScenarioControls() {
  const {CountryInfo, StateInfo,CountyInfo, PickedRegionModeling, setPickedCountry, PickedCountry, setPickedRegionModeling,loadStateInfo, loadCountyInfo,
    setPickedDataSource,PickedDataSource,} = useContext(GlobalContext);
  // const [dropdownOpen_country, setDropdownOpen_country] = useState(false);
  const [dropdownOpen_state, setDropdownOpen_state] = useState(false);
  const [dropdownOpen_county, setDropdownOpen_county] = useState(false);
  const [dropdownOpenModel, setDropdownOpenModel] = useState(false);
  const [dropdownDataSource, setDropdownDataSource] = useState(false);
  const [select_subregions_all, setSubregionSelection] = useState(false)
  // const toggle_country = () => setDropdownOpen_country(prevState => !prevState);
  const toggle_state = () => setDropdownOpen_state(prevState => !prevState);
  const toggle_county = () => setDropdownOpen_county(prevState => !prevState);
  const toggleDatasource = () => setDropdownDataSource(prevState => !prevState);
  const toggleModel = () => setDropdownOpenModel(prevState => !prevState);
  const {loadCovidModelingResults, appendCovidModelingResults, isTrainModel, setIsTrainModel, startTrainingDate, setTrainingStartDate, endTrainingDate, setTrainingEndDate, startPredictionDate, setPredictionStartDate, endPredictionDate, 
    setPredictionEndDate, AgeDistribution_SEIR, CasesCounts, covidModelingVisState, modelSelectionList, 
    setModelSelection} = useContext(GlobalContext);

  const handleClick_State = regionName => () => {
      let selectedRegion = (regionName == 'dropdown:select-state' || regionName == 'dropdown:select-all-state')? [PickedCountry] : [PickedCountry, regionName];
      setSubregionSelection((regionName == 'dropdown:select-all-state')? true : false)
      if (isSameRegion(selectedRegion, PickedRegionModeling)) { return; }
      loadCountyInfo(selectedRegion);
      setPickedRegionModeling(selectedRegion);
  }

  const handleClick_County = regionName => () => {
      //PickedRegionModeling's length has to be 2, including country and state.
      if (PickedRegionModeling.length <2) return;
      let selectedRegion = (regionName == 'dropdown:select-county' || regionName == 'dropdown:select-all-county')? PickedRegionModeling.slice(0,2) : [PickedCountry, PickedRegionModeling[1], regionName];
      setSubregionSelection((regionName == 'dropdown:select-all-county' && selectedRegion.length < 3)? true : false)
      setPickedRegionModeling(selectedRegion);
  }

  const handleClick_Datasource = selectedDatasource => () => {setPickedDataSource(selectedDatasource);}
  function handleCheck_ModelSelection(e) {
    var clickedModel = e.target.id;
    if (modelSelectionList.includes(clickedModel,0)){
      setModelSelection(prevState=>prevState.filter(x=> x !== clickedModel));
    }
    else{
      setModelSelection(prevState=>[...prevState,clickedModel]);
    }
    // console.log(modelSelectionList)
  }

  function handleClicked_Computation(e){
    // console.log()
    loadCovidModelingResults(PickedRegionModeling, select_subregions_all);
  }

const datasourceOptions = [
  {id:'NYTimes', displayName:'NYTimes'},{id:'UNSA', displayName:'UNSA'},{id:'JHU', displayName:'JHU'}, 
  {id:'CovidTrackingProject', displayName:'Covid Tracking Project'}];
  
// const modelOptions = [
//   // {id:'model-SIR(PanViz)', displayName:'SIR (PanViz)'},
//   // {id:'model-SEIR(Covid-Scenario)', displayName:'SEIR (Covid-Scenario)'},
//   {id:'model-CovidProjection', displayName:'SEIR (CovidProjection)'},
//   // {id:'model-DELPHI(MIT-COVIDAnalytics)', displayName:'SEIR (DELPHI)'},
//   {id:'model-Oklahoma', displayName:'Oklahoma simulation'},
//   // {id:'LSTM', displayName:'LSTM'},
// ];

const toggleModelTrainSimulate = ((e) => {
  setIsTrainModel(e.target.checked)
})
  return (
          <div style={{float:'left'}}>
              <h1 style={{textAlign: 'center'}}>COVID-19 Modeling</h1>
              <label> <b> Country:</b> {(PickedCountry!== undefined)?PickedCountry:'select a country'}</label>

              <Dropdown isOpen={dropdownOpen_state} toggle={toggle_state} style={{display:"inline-block"}}>
                <DropdownToggle caret size="sm" color="light">
                  {(PickedRegionModeling.length == 1)? (select_subregions_all? 'select all states' : 'select state') : PickedRegionModeling[1]}
                </DropdownToggle>

                <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                  <DropdownItem key = {'dropdown:select-state'} onClick={handleClick_State('dropdown:select-state')}>select state</DropdownItem>
                  <DropdownItem key = {'dropdown:select-all-state'} onClick={handleClick_State('dropdown:select-all-state')}>select all states</DropdownItem>
                  {
                    StateInfo.map((item)=>{
                      return <DropdownItem key={'dropdown:'+item['name']} onClick={handleClick_State(item['name'])}>
                          {item['name']}
                        </DropdownItem>
                    })
                  }
                </DropdownMenu>
              </Dropdown>

          {
            (PickedCountry === "United States" && PickedRegionModeling.length > 1) &&
            <Dropdown isOpen={dropdownOpen_county} toggle={toggle_county} style={{display:"inline-block"}}>
              <DropdownToggle caret size="sm" color="light">
              {(PickedRegionModeling.length < 3)? (select_subregions_all? 'select all counties' : 'select county') : PickedRegionModeling[2]}
              </DropdownToggle>

              <DropdownMenu style={{ maxHeight: "400px", overflow:"scroll"}}>
                <DropdownItem key = {'dropdown:select-county'} onClick={handleClick_County('dropdown:select-county')}>select county</DropdownItem>
                <DropdownItem key = {'dropdown:select-all-county'} onClick={handleClick_County('dropdown:select-all-county')}>select all counties</DropdownItem>
                {
                  CountyInfo.map((item)=>{
                    return <DropdownItem key={'dropdown:'+item['name']} onClick={handleClick_County(item['name'])}>
                        {item['name']}
                      </DropdownItem>
                    })
                }
              </DropdownMenu>
            </Dropdown>
          }
          <br />
          <label> <b>Data:</b> </label>
          <Dropdown isOpen={dropdownDataSource} toggle={toggleDatasource} style={{display:"inline-block"}}>
            <DropdownToggle caret size="sm" color="light">
              {(PickedDataSource=== '')?'select a datasource': PickedDataSource}
            </DropdownToggle>
            <DropdownMenu>
            {
                datasourceOptions.map((item)=>{
                    if ((PickedCountry === "Peru" && item.id === "UNSA") ||
                        (PickedCountry !== "Peru" && item.id !== "UNSA")) {
                      return <DropdownItem key={item.id} onClick={handleClick_Datasource(item.id)}>{item.displayName}</DropdownItem>
                    }
                  })
            }
            </DropdownMenu>
          </Dropdown>
          <br />
          <label> <b>Models:  </b> </label>
          {
              ModelOptions.map((item)=>{
                return <label key={"checkbox-" + item.id}>&nbsp;&nbsp;<input type="checkbox" name={'checkbox-' + item.displayName} key={'checkbox-' + item.id} id={item.id}
                      checked={modelSelectionList.includes(item.id, 0)}
                    onChange={handleCheck_ModelSelection} />
                    {item.displayName}&nbsp;&nbsp;&nbsp;&nbsp;</label>
              })
          }

        <br />
        <label><b>Training: </b>
            &nbsp;&nbsp;<input type="checkbox" name={"Train Model"} checked={isTrainModel} onChange={toggleModelTrainSimulate}/>
        </label>
        {isTrainModel && <DatePicker selected={startTrainingDate} onChange={date => setTrainingStartDate(date)} style={{ width: "100px",}}/>}
        {isTrainModel && <DatePicker selected={endTrainingDate}   onChange={date => setTrainingEndDate(date)} />}

        <br />

        <label style={{float:'left'}}><b>Prediction Time Range:</b></label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
            <DatePicker selected={startPredictionDate} onChange={date => setPredictionStartDate(date)} style={{ width: "100px",}}/>
        </div>
              <label style={{float:'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
            <DatePicker selected={endPredictionDate}   onChange={date => setPredictionEndDate(date)} />
        </div>

        <Button outline size="sm" onClick={handleClicked_Computation}>Compute</Button> {" "}
          {/* <Button variant="dark" onClick={handleClicked_Append}>Append</Button> */}
    
      </div>
        
        /* 
          <div>
          <AgeBarChart ageDistributionData={AgeDistribution_SEIR} result={R0_vals}/>
          </div>
         */
  );
}

export   {ModelCovidScenarioControls};

// function ModelCovidScenarioGraph() {
//
//   return (
//       <div style={{visibility: true ? 'visible' : 'hidden'}}>
//       <VisModelingResults />
//       </div>
//   )
// }

// export {ModelCovidScenarioButtons, ModelCovidScenarioGraph};