import React, {useContext, useState, useRef} from 'react'
import { GlobalContext } from '../GlobalContext';
import {Button } from 'reactstrap';
import {colorsRightAxis} from '../model_comparison/model_comparison_utilies';



const DecisionOptionPanel = ({width, height, posLeft, posTop, 
        handleEventCategorySelection, defaultVal}) =>{
  const {EventCategories} = useContext(GlobalContext);
  const [EventVisibilities, setEventVisibility] = useState(defaultVal);
  const selectEventCategory = ((e)=>{
    console.log(e.target.name)
    var s = [];
    if (EventVisibilities.includes(e.target.name)) {
      EventVisibilities.forEach((n)=>{
        if (n!= e.target.name) 
          s.push(n);
      })
    }
    else {
      EventVisibilities.forEach((n)=>{
          s.push(n);
      })
      s.push(e.target.name);
    }
    setEventVisibility(s);
    // setIndexOfCheckedBox(e.target.value)
    // let str = (e.target.value <0)?null:EventCategories[e.target.value]
    // handleEventCategorySelection(str)
  })

  const clickButton = ((e)=>{handleEventCategorySelection(EventVisibilities);});
return (<>
       <label >Select event category:</label> 
       {EventCategories.map((category, i)=>{
        return  <label key={"DecisionOptionPanel-EventCategory-"+category}
                style={{display:"block", 
                color:colorsRightAxis[i%colorsRightAxis.length]}}>
                <input type="checkbox"
                        checked={EventVisibilities.includes(category)}
                        onChange={selectEventCategory}
                        name={category}/>
                {category}
                </label>
        })}
        <Button onClick={clickButton} size="sm">Submit</Button>
    </>)
}

export default DecisionOptionPanel;

