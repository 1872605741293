
function transformDataV2(data){
    // console.log(data)
    var legends = {scenario:[], eventsForRightAxis:[], referenceAreas:[]};
    var plots = [];
    
    data.forEach((modelInfo, index)=>{
        if(modelInfo.modelname == "")
            return
        var scenario = modelInfo.modelname + '(' + modelInfo.region.toString()+ ')(' + modelInfo.policyDataTag+')';
        legends["scenario"].push(scenario)
        Object.keys(modelInfo.paramestimation).forEach((plotName, i)=>{
            let index = plots.findIndex(x=>x.name==plotName);
            if (index<0){
                plots.push({name: plotName})
                index = plots.length-1;
            }
            var tuples = plots[index];
            var paramVals = modelInfo.paramestimation[plotName];
            Object.keys(paramVals).forEach((paramName, i)=>{
                var paramVal = paramVals[paramName]
                if (typeof paramVal == "number")
                {
                    if(!("singleValue" in tuples)) {
                        tuples["singleValue"] = []                   
                    }
                    var cindex = tuples["singleValue"].findIndex(x=>x.name==paramName);
                    if (cindex<0) {
                        var cc = {}
                        cc["name"] = paramName
                        tuples["singleValue"].push(cc)
                        cindex = tuples["singleValue"].length-1
                    }
                    var tmp = tuples["singleValue"][cindex]
                    tmp[scenario] = paramVal
                }
                else if (Array.isArray(paramVal)){
                    if (paramName == 'distribution' || paramName == 'daily_import') {//transform data for histogram bar chart
                        if(!("hist" in tuples)) {
                            tuples["hist"]= [];
                        }
                        var cindex = tuples["hist"].findIndex(x=>x.name==paramName);
                        if (cindex<0) {
                            var cc = {}
                            cc["name"] = paramName
                            tuples["hist"].push(cc)
                            cc["value"] = paramVal.map((m,i)=>{
                                var tmp = {}
                                tmp[scenario] = m.val
                                tmp["dayIndex"] = m.day
                                return tmp
                            })
                        }else{
                            var cc = tuples["hist"][cindex]
                            cc.value.forEach((e)=>{e[scenario] = undefined})
                            paramVal.forEach(e => {
                                var tIndex = cc.value.findIndex(x=>x.dayIndex == e.day)
                                if (tIndex <0) {
                                    let newElement = Object.assign({}, cc.value[0]);
                                    Object.keys(newElement).forEach((k, i) =>{newElement[k]=undefined})
                                    newElement.dayIndex = e.day
                                    newElement[scenario] = e.val
                                    cc.value.push(newElement)
                                }
                                else {
                                    cc.value[tIndex][scenario] = e.val
                                }
                            });
                        }
                    }
                    else {//to do
                        if(!("leftAxis" in tuples)) {
                            tuples["leftAxis"]= [];
                        }
                        var cindex = tuples["leftAxis"].findIndex(x=>x.name==paramName);
                        if (cindex<0) {
                            var cc = {"name":paramName}
                            tuples["leftAxis"].push(cc)
                            var arrayVals = paramVal.map((m, i) =>{ 
                                var tmp = {}
                                tmp[scenario] = m.val
                                tmp['dayIndex'] = m.day
                                return tmp
                            })
                            cc["value"] = arrayVals
                        }
                        else {
                            var cc = tuples["leftAxis"][cindex]
                            cc.value.forEach((e)=>{e[scenario] = undefined})
                            paramVal.forEach(e=> {
                                var tIndex = cc.value.findIndex(x=>x.dayIndex == e.day)
                                if (tIndex <0) {
                                    let newElement = Object.assign({}, cc.value[0]);
                                    Object.keys(newElement).forEach((k, i) =>{newElement[k]=undefined})
                                    newElement.dayIndex = e.day
                                    newElement[scenario] = e.val
                                    var firstLargerVal = cc.value.findIndex(x=>x.dayIndex > e.day);
                                    if (firstLargerVal < 0) {
                                        cc.value.push(newElement)
                                    }
                                    else if (firstLargerVal==0) {
                                        cc.value.unshift(newElement);
                                    }
                                    else {
                                        cc.value.splice(firstLargerVal, 0, newElement);
                                    }
                                }
                                else {
                                    cc.value[tIndex][scenario] = e.val
                                }
                            })
                        }
                    } 
                }
                else {//dictionary
                    if ("multiplier" in paramVal) {
                        if (!('rightAxis' in tuples)) {
                            tuples['rightAxis'] = []
                        }
                        var cindex = tuples['rightAxis'].findIndex(x=>x.name == paramName)
                        if(cindex<0){
                            var c = {}
                            c["name"] = paramName
                            tuples['rightAxis'].push(c);
                            var tmp = {}
                            tmp[scenario] = paramVal
                            c["value"] = [tmp]
                        }
                        else{
                            var cc = tuples["rightAxis"][cindex]
                            var tmp = {}
                            tmp[scenario] = paramVal
                            cc.value.push(tmp)
                            
                        }
                        if (legends.eventsForRightAxis.findIndex(x=>x==paramName)<0) {
                            legends.eventsForRightAxis.push(paramName)
                        }
                    }
                    else{
                        if (!('referenceAreas' in tuples)) {
                            tuples['referenceAreas'] = []
                        }
                        var cindex = tuples['referenceAreas'].findIndex(x=>x.name == paramName)
                        if (cindex<0) {
                            var c = {}
                            c["name"] = paramName
                            tuples['referenceAreas'].push(c);
                            var tmp = {}
                            tmp[scenario] = paramVal
                            c["value"] = tmp
                        } else {
                            var cc = tuples["referenceAreas"][cindex]
                            cc.value[scenario] = paramVal
                        }
                        if (legends.referenceAreas.findIndex(x=>x==paramName)<0) {
                            legends.referenceAreas.push(paramName)
                        }
                    }
                }
            }
            )
        })
    });

    return [legends, plots]
}

export default transformDataV2