// import { lab, rollup } from 'd3';
import React, {useContext, useState} from 'react';
import { GlobalContext } from '../GlobalContext';
import { Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import '../css_config/ReactDatePickerStyles.css'

var lodash_1 = require("lodash");

const EventEditor = ({width, height})=>{
  const {MitigationData, setMitigationData, 
         AccelerationData, setAccelerationData, 
         SelectedRegionOfEventView, PolicyDataset} = useContext(GlobalContext);
  const [StartDate, setStartDate] = useState(Date.now);
  const [EndDate, setEndDate]   = useState(Date.now);
  const [NewEventName, setNewEventName] = useState('')
  const [EventType, setEventType] = useState('mitigation')
  const addEventName = ((e)=>{
    setNewEventName(e.target.value)
  })
  const addEvent=((e)=>{
    if (NewEventName!=''){
      // console.log(MitigationData)
      // console.log(AccelerationData)
      let date_start_str = (new Date(StartDate)).toISOString().split('T')[0]
      let date_end_str = (new Date(EndDate)).toISOString().split('T')[0]
      if(EventType == "acceleration") {
        let newItem = {
          date_start:date_start_str,
          date_end:date_end_str,
          name:NewEventName
        }
        let aindex = AccelerationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset);
        let clone = lodash_1.cloneDeep(AccelerationData);
        clone[aindex].events.push(newItem);
        setAccelerationData(clone);
        // setAccelerationData(state => [...state, newItem])
        // sendDataToParent({data:newItem, type:EventType})
      }
      if(EventType == "mitigation") {
        let newItem = { name:NewEventName, 
          timeRange:{begin: date_start_str, end: date_end_str},
          transmissionReduction:{begin:0.1, end: 0.15}
        }
        let mindex = MitigationData.findIndex(x=>x.fips == SelectedRegionOfEventView && x.dataset==PolicyDataset)
        // console.log(newItem)
        let clone = lodash_1.cloneDeep(MitigationData);
        clone[mindex].events.push(newItem);
        setMitigationData(clone)
        // setMitigationData(state => [...state, newItem]);
        // sendDataToParent({data:newItem, type:EventType})
      }
      setNewEventName('')
    }
  })
  const selectEventType = ((e)=>{
    setEventType(e.target.value)
  })

  return (
      <div id="event_editor" style={{background:"#e6ffff"}}>
        <h4 style={{textAlign: 'left'}}>Event Setting</h4> 
        <label style={{float:'left'}}>Type:&nbsp;</label>
        <div onChange={selectEventType} style={{float:'left'}}>
          <input type="radio" name="eventType" value="mitigation" defaultChecked/>
            &nbsp;Mitigation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <input type="radio" name="eventType" value="acceleration"/>
            &nbsp;Exacerbation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div key="add-event-panel" style={{display: 'inline-block'}}>
        <label style={{float:'left'}}>Begin:&nbsp;</label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
          <DatePicker selected={StartDate} onChange={date => setStartDate(date)}/>
        </div>
        <label style={{float:'left'}}>&nbsp;&nbsp;End:&nbsp;</label>
        <div className="customDatePickerWidth" style={{float:'left', width:"100px"}}>
          <DatePicker selected={EndDate} onChange={date => setEndDate(date)}/>
        </div>
        <label style={{float:'left'}}>&nbsp;&nbsp;Event Name:&nbsp;
          <input type="text" style={{width:"150px", float:"leaft", height:"25px"}}
                 value={NewEventName} onChange={addEventName}/>
          &nbsp;
        </label>
        <Button outline size="sm" style={{width:"105px", height:"25px",
          marginTop:"-1px", paddingTop:"0px"}} onClick={addEvent}>Add Event</Button>
        </div>
        
      </div>
  );
}
export default EventEditor;