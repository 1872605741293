import React, { useState, useContext, useEffect } from "react";
import {GlobalContext} from "../GlobalContext";
import {isSubRegion} from "../RegionConcepts/RegionUtils";

function DataOptionList() {
    const {PredictionData, RegionOnMap, setMapDisplayDataOption} = useContext(GlobalContext);
    const [indexOfCheckedRadio, setIndexOfCheckedRadio] = useState(0);
    const [DataOptions, setDataOptions] = useState(["Observed"])
    const selectionUpdate = ((e)=>{
        setIndexOfCheckedRadio(e.target.value)
        if (e.target.value >= 0) {
            let tokens = DataOptions[e.target.value].split('[')
            setMapDisplayDataOption(tokens[0].trim())
        }
    })

    useEffect(()=>{
        let optionsObserved = ["Observed"]
        let optionsModeling = PredictionData.filter(item=>isSubRegion(RegionOnMap, item.region))//.map(item=>item.modelname)
        if (optionsModeling == undefined || optionsObserved.length==0) {
            setDataOptions(optionsObserved)
        } else {
            let model_names = optionsModeling.map(item=>item.modelname)
            let unique_names = model_names.reduce((result, subject) => {
                return result.includes(subject) ? result : [...result, subject];
            }, []);
            let model_label_list = unique_names.map(model_name => [model_name, '', ''])
            optionsModeling.forEach(item => {
                let timestamps = item.prediction.map(record => record.timestr).sort()
                let model_label = model_label_list.filter(label => label[0] == item.modelname)[0]
                if (model_label[1] == '' || model_label[1] > timestamps[0]) {
                    model_label[1] = timestamps[0]
                }
                if (model_label[2] == '' || model_label[2] < timestamps[timestamps.length - 1]) {
                    model_label[2] = timestamps[timestamps.length - 1]
                }
            })
            let labels = model_label_list.map(label_arr => label_arr[0] + "[" + label_arr[1] + ", " + label_arr[2]  + "]")
            setDataOptions(optionsObserved.concat(labels))
        }
    }, [RegionOnMap, PredictionData]);

    return (<>
        { DataOptions.length > 0 &&
            DataOptions.map((name, i)=>{
                return  <label key={"MapDisplayData-"+i}
                               style={{display:"inline-block", }}>
                    <input type="radio"
                           checked={(i)==indexOfCheckedRadio}
                           onChange={selectionUpdate}
                           value={i}/>
                    {name}&nbsp;&nbsp;
                </label>
            })
        }

    </>)
}


export default DataOptionList;