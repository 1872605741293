import React, { Component,useContext, useState } from 'react'
// import { GlobalContext } from '../GlobalContext';
import {withResizeDetector} from 'react-resize-detector';
import EventEditor from './EventEditor.js'
import VisEventCategory from './EventCategory'
import EventBarChart from './EventBarChart'
import EventsRegionOptionPanel from './EventsRegionOptionPanel'
import { GlobalContext} from '../GlobalContext';

const AdaptiveEventsView = (({ width, height }) =>{
    return (
        <div id="EventsView" className="w-400 h-100">
            <h1 style={{textAlign: 'center'}}>Intervention Planning</h1> 
            <EventBarChart width={width} height={500}/>    
            <VisEventCategory width={width}></VisEventCategory>
            <EventEditor></EventEditor>
        </div>);
});
const EventsView = withResizeDetector(AdaptiveEventsView);

export default EventsView
