const Shapefiles_US=[
  {id: ["United States"], url: "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json", subregion_key:["id"]},
  {id: ["United States-counties"], url: "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json", subregion_key:["id"]},
//  {id: ["United States","Oklahoma"], url: "https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/OK-40-oklahoma-counties.json", subregion_key:["properties", "GEOID"]},
//  {id: ["United States","Ohio"],   url: "https://github.com/guizhen-wang/topojson/master/countries/us-states/OH-39-ohio-counties.json", subregion_key:["properties", "GEOID"]},
//  {id: ["United States","California"], url: "https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/CA-06-california-counties.json", subregion_key:["properties", "GEOID"]}
//
  {
    'id': ['United States','Alabama'],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/AL-01-alabama-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Alaska'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/AK-02-alaska-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Arizona'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/AZ-04-arizona-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Arkansas'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/AR-05-arkansas-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'California'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/CA-06-california-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Colorado'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/CO-08-colorado-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Connecticut'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/CT-09-connecticut-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Delaware'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/DE-10-delaware-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'District of Columbia'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/DC-11-district-of-columbia-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Florida'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/FL-12-florida-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Georgia'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/GA-13-georgia-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Hawaii'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/HI-15-hawaii-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Idaho'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/ID-16-idaho-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Illinois'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/IL-17-illinois-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Indiana'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/IN-18-indiana-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Iowa'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/IA-19-iowa-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Kansas'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/KS-20-kansas-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Kentucky'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/KY-21-kentucky-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Louisiana'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/LA-22-louisiana-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Maine'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/ME-23-maine-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Maryland'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MD-24-maryland-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Massachusetts'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MA-25-massachusetts-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Michigan'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MI-26-michigan-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Minnesota'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MN-27-minnesota-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Mississippi'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MS-28-mississippi-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Missouri'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MO-29-missouri-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Montana'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MT-30-montana-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Nebraska'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NE-31-nebraska-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Nevada'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NV-32-nevada-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'New Hampshire'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NH-33-new-hampshire-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'New Jersey'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NJ-34-new-jersey-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'New Mexico'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NM-35-new-mexico-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'New York'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NY-36-new-york-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'North Carolina'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/NC-37-north-carolina-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'North Dakota'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/ND-38-north-dakota-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Ohio'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/OH-39-ohio-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Oklahoma'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/OK-40-oklahoma-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Oregon'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/OR-41-oregon-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Pennsylvania'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/PA-42-pennsylvania-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Rhode Island'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/RI-44-rhode-island-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'South Carolina'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/SC-45-south-carolina-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'South Dakota'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/SD-46-south-dakota-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Tennessee'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/TN-47-tennessee-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Texas'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/TX-48-texas-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Utah'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/UT-49-utah-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Vermont'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/VT-50-vermont-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Virginia'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/VA-51-virginia-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Washington'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/WA-53-washington-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'West Virginia'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/WV-54-west-virginia-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Wisconsin'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/WI-55-wisconsin-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Wyoming'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/WY-56-wyoming-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'American Samoa'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/AS-60-american-samoa-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Guam'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/GU-66-guam-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Northern Mariana Islands'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/MP-69-northern-mariana-islands-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'Puerto Rico'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/PR-72-puerto-rico-municipios.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'U.S. Minor Outlying Islands'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/UM-74-u.s.-minor-outlying-islands-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  },
  {
    'id': [
      'United States',
      'U.S. Virgin Islands'
    ],
    'url': 'https://raw.githubusercontent.com/guizhen-wang/topojson/master/countries/us-states/VI-78-u.s.-virgin-islands-counties.json',
    'subregion_key': [
      'properties',
      'GEOID'
    ]
  }
]

export {Shapefiles_US}