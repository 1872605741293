import React, { useContext } from 'react';
import MapView from './map_view/MapView';
import {Card, CardBody} from 'reactstrap';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import {Resizable, ResizableBox} from 'react-resizable';
// import {Rnd} from 'react-rnd';
import './resize.css';
import {ModelCovidScenarioControls} from './ModelCovidScenarioControls'
import {GlobalContext } from './GlobalContext';
import EventsView from './events_view/EventsView';
import ModelComparison from './model_comparison/modelcomparison'
import DecisionMeasureView from './decision-measure/DecisionMeasureView';
import StateTransitionView from './seir_view/StateTransitionView'
import TimeSeriesView from './TimeSeriesDataView/TimeSeriesView';

// layout component 
function PanVis() {
    const {simulationState} = useContext(GlobalContext);

    return (
        <>
            <div id ='map-hover' style={{visibility: 'hidden', position: 'absolute', zIndex: 999}}>
                <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.5em', marginLeft: '.5em', marginTop: '.25em', marginBottom: '.25em'}}>
                    <CardBody style={{padding: '5px'}}>
                        <div>
                            {simulationState.hoveredRegion.region}
                            {Object.keys(simulationState.hoveredRegion).filter((key)=> key != 'region').map(key=>{
                                return <div key={'map-hover-'+key}>
                                    {key}(absolute): {simulationState.hoveredRegion[key]}
                                </div>
                            })}
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Container fluid>
                <Row xxl={2} lg={2} md={1} sm={1} xl={2} xs={1}>
                    <Col>
                        <Stack >
                             <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '.2em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <MapView />
                                </CardBody>
                             </Card>
                            <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '.2em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <ModelCovidScenarioControls />
                                </CardBody>
                            </Card>
                             <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '.2em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <Stack>
                                        <TimeSeriesView />
                                    </Stack>
                                </CardBody>
                             </Card>
                             <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.01em', marginLeft: '.2em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <StateTransitionView />
                                </CardBody>
                             </Card>
                        </Stack>
                    </Col>
                    <Col>
                        <Stack >
                            <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.2em', marginLeft: '.01em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <EventsView />
                                </CardBody>
                            </Card>
                            <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.2em', marginLeft: '.01em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <ModelComparison />
                                </CardBody>
                            </Card>
                            <Card style={{boxShadow: '0 1px 4px rgba(0,0,0,.4)', marginRight: '.2em', marginLeft: '.01em', marginTop: '.25em', marginBottom: '.25em'}}>
                                <CardBody style={{padding: '5px'}}>
                                    <DecisionMeasureView />
                                </CardBody>
                            </Card>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PanVis;
