import React, { useState, useContext, useEffect } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { GlobalContext } from '../GlobalContext';
import { colorConstants } from '../Constants';
import * as d3 from "d3";
import * as topojson from "topojson-client";
import {getShapefileUrl, isSameRegion, getMapProjection} from '../RegionConcepts/RegionUtils';
// import {convertDate2Str} from "../DateTimeUtils";
import {extractObservationDataForMapDisplay, extractModelingDataForMapDisplay, getObservedDataForMapDisplay} from './MapDataProcess'

// const width = 800, height = 600;
const width = 800, height = 550;
// function getmap (width, height, scale, geos) {
//     return d3.geoPath().projection(d3.geoMercator().scale(scale).translate([width*3, height *0.0001]));
// }

function Map() {
    const {simulationState, setSimulationState, PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate, PredictionData, getRegionCode, MapDisplayDataOption, MapDataCategory } = useContext(GlobalContext);
    // const [shapefileData, setShapefileData] = useState([]);
    const [mappedSimulationData, setMappedSimulationData] = useState({});
    const [geoUrl, setGeoURL] = useState('')
    const [subregionId, setSubRegionId] = useState('')
    const [DataDisplayOnMap, setDataDisplayOnMap] = useState({}) //[{mapId:"", color:"", value:[]}]
    const scale = (PickedCountry =='United States')? 900: 200;
    const getSubRegionId = (geo) => {
        // console.log(geo)
        // console.log(subregionId)
        let idName = ''
        if (subregionId.length==1) {
            idName = geo[subregionId[0]]
        }
        else if (subregionId.length==2) {
            let element = geo[subregionId[0]]
            idName = element[subregionId[1]]
        }
        return idName
    }

    useEffect(()=>{
        let [res_geoUrl, res_subregionId] = getShapefileUrl(PickedCountry, RegionOnMap);
        setGeoURL(res_geoUrl)
        setSubRegionId(res_subregionId)
    }, [PickedCountry, RegionOnMap])

    //change simulationData
    useEffect(() => {
        let simulationMap = {};
        simulationState.simulationData.forEach(d => {
            simulationMap[d.id] = d
        });
        setMappedSimulationData(simulationMap);
    }, [simulationState.simulationData]);

    useEffect(()=>{
        // console.log([RegionOnMap, MapDisplayDataOption, MapDisplayDate])
        if (MapDisplayDataOption == "Observed") {
            // console.log(extractObservationDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate))
            setDataDisplayOnMap(extractObservationDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate, MapDataCategory))
        } else {
            setDataDisplayOnMap(extractModelingDataForMapDisplay(PickedCountry, RegionOnMap, MapDisplayDate, PredictionData, MapDisplayDataOption, MapDataCategory))
        }
    }, [RegionOnMap, MapDisplayDate, PredictionData, ObservationDataMap, MapDataCategory])

    //change simulationState
    const handleCountyClick = geoId => () => {
        setSimulationState(simulationState => {
            return({
                ...simulationState,
                selectedCounty: geoId
            });
        });
    }

//    console.log(DataDisplayOnMap)

    return (
        <div className="map" style={{ backgroundColor: '#f8f9fa',/*, width: simulationState.showCaseGraphs ? "50%" : "90%", height: "100%", left: "10%", position: "absolute"*/}}>
            <ComposableMap projection={getMapProjection(RegionOnMap)}
                           width={width} height={height} projectionConfig={{scale: scale}}>
                <ZoomableGroup>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            {
                                let paths = geographies.map(geo => {
                                    const cur = mappedSimulationData[geo.id];
                                    let geoId = getSubRegionId(geo)
                                    let dataForDisplay = DataDisplayOnMap[geoId]
//                                    console.log(dataForDisplay)
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            style={{
                                                default: {
                                                    fill: dataForDisplay==undefined? colorConstants.colorMap[0] : dataForDisplay.color,
                                                    stroke: "#000",
                                                    strokeWidth: cur && cur.id === simulationState.selectedCounty ? 1 : 0.25,
                                                    outline: "none",
                                                    opacity: 0.8,
                                                },
                                                hover: {
                                                    fill: "#AAA",
                                                    stroke: "#000",
                                                    strokeWidth: cur && cur.id === simulationState.selectedCounty ? 1 : 0.25,
                                                    outline: "none",
                                                }
                                            }}
                                            onClick={handleCountyClick(geoId)}
                                            onMouseEnter={(evt) => {
                                                var left = evt.clientX + 'px';
                                                var top = evt.clientY + 'px';
                                                var div = document.getElementById('map-hover');

                                                setSimulationState(simulationState => {
                                                    // let dataPerGeo = getMapData(geoId)
                                                    return ({
                                                        ...simulationState,
                                                        hoveredRegion: (dataForDisplay==undefined)? {region: 'NA'} : Object.assign({}, ...
                                                                Object.entries(dataForDisplay).filter(([k,v]) => !['mapId', 'color', 'time', 'timestr'].includes(k)).map(([k,v]) => ({[k]:v})))
                                                    });
                                                });

                                                if (div.style.left != left && div.style.top != top) { //prevent flickering
                                                    div.style.left = left;
                                                    div.style.top = top;
                                                    div.style.visibility = 'visible'
                                                }
                                            }}
                                            onMouseLeave={(evt) => {
                                                var div = document.getElementById('map-hover');
                                                var left = evt.clientX + 'px';
                                                var top = evt.clientY + 'px';
                                                if (div.style.left != left && div.style.top != top) { //prevent flickering
                                                    div.style.visibility = 'hidden';
                                                }
                                            }}
                                        />
                                    );
                                });

                               
                                return paths;
                            }
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </div>
    );
}

export default Map;

// var projection = d3.geoMercator()
    //     .scale(1)
    //     .translate([0, 0]);
    
    // // Create a path generator.
    // var path = d3.geoPath()
    //     .projection(projection);
    
    // // Compute the bounds of a feature of interest, then derive scale & translate.
    // var b = path.bounds(geos),
    //     s = .95 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height),
    //     t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];
    
    // // Update the projection to use computed scale & translate.
    // return projection
    //     .scale(s)
    //     .translate(t);

    // function getColor(county) {
    //     if (!colorState.colorings.length) {
    //         return colorConstants.colorMap[0];
    //     }
        
    //     let index = Math.min(simulationState.simulationTimeStep, simulationState.simulationData[0][colorState.colorings[0]].length - 1);

    //     let ratio = county[colorState.colorings[0]][index];
    //     (colorState.colorings).slice(1).forEach((el) => {
    //         ratio += county[el][index];
    //     });
    //     ratio /= county.population;

    //     let color;

    //     if (ratio <= 0.5 * colorState.colorScale) {
    //         color = colorConstants.colorMap[0]
    //     }
    //     else if (ratio > 0.5 * colorState.colorScale && ratio <= colorState.colorScale) {
    //         color = colorConstants.colorMap[1]
    //     }
    //     else if (ratio > colorState.colorScale && ratio <= 2.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[2]
    //     }
    //     else if (ratio > 2.0 * colorState.colorScale && ratio <= 3.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[3]
    //     }
    //     else if (ratio > 3.0 * colorState.colorScale && ratio <= 4.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[4]
    //     }
    //     else if (ratio > 4.0 * colorState.colorScale && ratio <= 5.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[5]
    //     }
    //     else if (ratio > 5.0 * colorState.colorScale && ratio <= 6.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[6]
    //     }
    //     else if (ratio > 6.0 * colorState.colorScale && ratio <= 7.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[7]
    //     }
    //     else if (ratio > 7.0 * colorState.colorScale && ratio <= 8.0 * colorState.colorScale) {
    //         color = colorConstants.colorMap[8]
    //     }
    //     else {
    //         color = colorConstants.colorMap[9]
    //     }

    //     return color;
    // }

     // if (Object.keys(shapefileData).length && simulationState.showCountyCaseGraphs && isVisUS) {
                                //     paths.push(
                                //         <path 
                                //             tabIndex={0} 
                                //             key="geo-state" 
                                //             className="rsm-geography" 
                                //             fill="none" 
                                //             stroke ="#000" 
                                //             strokeWidth={0.75} 
                                //             d={shapefileData && shapefileData.objects ? map(topojson.mesh(shapefileData, shapefileData.objects.states)): []} 
                                //         />
                                //     );
                                // }
                                // else { // show state
                                //     paths = [
                                //         <path 
                                //         tabIndex={0} 
                                //         key="geo-state" 
                                //         className="rsm-geography" 
                                //         fill="#2c7fb8" 
                                //         stroke ="#000" 
                                //         strokeWidth={0.75} 
                                //         d={shapefileData && shapefileData.objects ? map(topojson.mesh(shapefileData, 
                                //             (isVisUS? shapefileData.objects.states : shapefileData.objects['peru-departments\'']))): []} 
                                //     />
                                //     ];
                                // }
