import React, { createContext, useState, useEffect, useLayoutEffect } from 'react';
import { csv } from 'd3-fetch';
import { deftmodel, defaultparams, setparams, covidModelingResults, stateDataIOModule, countyDataIOModule, covidObservations, covidObservationsForMap, mitigationDataModule, acclerationDataModule, initBackend} from './API';
import { colorConstants, defaultModelParams, unemploymentDataConstants, observedDataConstants, STLDataConstants, policyCategories} from './Constants';
import {initDataFormat, initAgeGroups} from './scenarios/SEIRInputs'
// import { getRegionId } from './FunctionsCommon';
import {COUNTRY_Alpha_3_Code} from './RegionConcepts/RegionConstants'
import {convertDate2Str} from './DateTimeUtils'
import {isSameRegion, isSubRegion} from "./RegionConcepts/RegionUtils";
import {getObservedDataForMapDisplay} from './map_view/MapDataProcess';

var lodash_1 = require("lodash");

const GlobalContext = createContext();

const loadSimulationHelper = (json_data, counties, state) => {
    let filtered_counties = counties.filter(d => {
        return String(d.name.split(unemploymentDataConstants.commaSplit)[1]).trim() === state;
    })
    .map(d => {
        var countyName;
        if (state === 'AK') {
            countyName = d.name.split(unemploymentDataConstants.commaSplit)[0];
        }
        else if (state === 'LA') {
            countyName = d.name.split(unemploymentDataConstants.parishSplit)[0]
        }
        else {
            countyName = d.name.split(unemploymentDataConstants.countySplit)[0]
        }

        return ({
            ...d, 
            county: countyName
        });
    });

    return json_data.map(d => {
        let county_id = filtered_counties.find(e => e.county === d.name);
        if (county_id) {
            d = {...d, id: county_id.id, state: state}
        }
        return d;
    });
};

const GlobalContextProvider = props => {
    const [simulationState, setSimulationState] = useState({
        simulationIsRunning: false,
        simulationTimeStep: 0,
        intervalId: null,
        observedData: {
            countyData: [],
            stateData: [],
            startDate: new Date()
        },
        simulationData: [],
        STLData: {
            countyData: [],
            stateData: []
        },
        simulationSpeed: 400,
        isDraggable: false,
        simulationDataIsLoading: true,
        modelParams: defaultModelParams,
        editModelParams: true,
        hoveredRegion: '40109',
        selectedCounty: '40109',
        showCaseGraphs: false,
        showCountyCaseGraphs: false,
        showStateCaseGraphs: false,
        showSimulatedCaseGraphs: false,
        showObservedCaseGraphs: true,
        showSTLCaseGraphs: false,
        showCasePercentage: false
    });
   
    const [populationState, setPopulationState] = useState({
        showUnderEighteen: true,
        showEighteenToSixtyFive: true,
        showSixtyfiveOver: true
    });
    const [colorState, setColorState] = useState({
        colorScale: 0,
        coloringShow: colorConstants.colorValues.colorAllCasesValue,
        coloringCategory: colorConstants.colorValues.colorAllSickValue,
        colorings: [colorConstants.colorValues.colorAllSickValue]
    });

   
    const [AgeDistribution_SEIR, setAgeInfo]  = useState(initAgeGroups());

    // const [covidModelingVisState, setCovidModelState] = useState({ObservationData: null, PredictionData: null, ScenarioInfo:null});
    // const [CasesCounts, setCasesCounts]  = useState({data:[{time:"", cases:null, deaths:null, hospitalized:null, icu:null, recovered:null}], name:""});
    // const [startPredictionDate, setPredictionStartDate] = useState(new Date(2020, 11, 1));
    // const [endPredictionDate, setPredictionEndDate] = useState(new Date());
    const [isTrainModel, setIsTrainModel] = useState(false)
    const [startTrainingDate, setTrainingStartDate] = useState(new Date(2021, 0, 1));
    const [endTrainingDate, setTrainingEndDate] = useState(new Date(Date.now() - 2*( 3600 * 1000 * 24)));
    const [startPredictionDate, setPredictionStartDate] = useState(new Date(Date.now() - ( 3600 * 1000 * 24)));
    const [endPredictionDate, setPredictionEndDate] = useState(new Date(Date.now() + 15*( 3600 * 1000 * 24)));

    // const [startMapDate, setMapStartDate] = useState(new Date(2021, 0, 1));
    // const [endMapDate, setMapEndDate] = useState(new Date(2021, 2, 1));
    const [MapDisplayDate, setMapDisplayDate] = useState(new Date(2022, 3, 10));
    const [MapDataCategoryList, setMapDataCategoryList] = useState([])
    const [MapDataCategory, setMapDataCategory] = useState('')
    /***********************Demographic info***************************/
    const [PickedCountry, setPickedCountry] = useState('United States');
    const [PickedRegionModeling, setPickedRegionModeling] = useState(['United States', 'Oklahoma']);
    const [RegionOnMap, SetRegionOnMap] = useState(['United States']);
    const [MapDisplayDataOption, setMapDisplayDataOption] = useState('');
    // const [PickedCountyFipsCode, setCountyFIPSCode] = useState('');
    const [CountryInfo, setCountryInfo] = useState([{name: "United States", population:329500000, isocode:"USA"}, 
                                                    {name: "Peru", population:32970000, isocode:"PER"}])
    const [StateInfo, setStateInfo] = useState([{name: "", population:0, fips:""}])
    const [CountyInfo, setCountyInfo] = useState([{name: "", population:0, fips:""}])
   
    const loadStateInfo = (country) => {
        fetch(stateDataIOModule, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'country':country})}
        )
        .then(res => res.json())
        .then(data => {
            setStateInfo(data)
        });
    }

    const loadCountyInfo = (region) =>{
        if(region.length !=2) return;
        fetch(countyDataIOModule, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'region':region})})
        .then(response=> response.json())
        .then((data) => {
            // console.log(data)
            setCountyInfo(data)
          })
    };
  /**************************************************************************/

  /*********Load covid data*************************************************/
  const [PickedDataSource, setPickedDataSource] = useState('NYTimes');
  const [ObservationDataModeling, setObservationDataModeling] = useState([
      {region:[], data:[{time:"", observedCases:null, observedDeaths:null}]}]);
  const [ObservationDataMap, setObservationDataMap] = useState([
        {region:[], data:[{time:"", observedCases:null, observedDeaths:null}]}]);
  const loadCovidObservations=(selectedRegion)=>{
      // let selectedFipsCode = getRegionCode(selectedRegion)
        fetch(covidObservations,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'region':selectedRegion, 'dataSource':PickedDataSource})})
        .then(response => response.json())
        .then((jsonData) => {
            // console.log(jsonData)
            setObservationDataModeling([{'region':selectedRegion, 'data':jsonData}])
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    };

    const loadCovidObservationsForMap=(selectedRegion)=>{
        // let selectedFipsCode = getRegionCode(selectedRegion)
        // console.log(selectedFipsCode)
        let daystr = convertDate2Str(MapDisplayDate)//.toISOString().split('T')[0]
        fetch(covidObservationsForMap,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'region':selectedRegion, 'dataSource':PickedDataSource, 'time': daystr})})
        .then(response => response.json())
        .then((jsonData) => {
            setObservationDataMap([{'region':selectedRegion, 'datestr':daystr, 'data':jsonData}])
            if (MapDisplayDataOption == '') {
             setMapDisplayDataOption('Observed')
            }
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    };

    useEffect(()=>{
        function extractKeys(item) {
//            let firstRegion = Object.keys(item)
//             let items = item[firstRegion]
//             if (items instanceof Array) {
//                items = items[0]
//             }
             let categories = Object.keys(item).filter(key => !['mapId', 'region', 'time', 'timestr'].includes(key))
             return categories
        }
         if (MapDisplayDataOption == "Observed") {
            let data = getObservedDataForMapDisplay(PickedCountry, RegionOnMap, ObservationDataMap, MapDisplayDate)
//            console.log(data)
            if (data.length > 0) {
                 let categories = extractKeys(data[0])
                 setMapDataCategoryList(categories)
                 setMapDataCategory(categories[0])
            }
        } else {
            let elements = PredictionData.filter(item => isSubRegion(RegionOnMap, item.region) && (item.modelname==MapDisplayDataOption) && (item.prediction.length>0))
            if (elements.length > 0) {
                console.log(elements[0])
                let categories = extractKeys(elements[0].prediction[0])
                setMapDataCategoryList(categories)
                setMapDataCategory(categories[0])
            }
        }
    }, [MapDisplayDataOption])

    /*********Load covid intervention data*************************************************/
    const [MitigationData, setMitigationData] = useState([{region:[], dataset:"Oklahoma", events:[]}]);
    const [AccelerationData, setAccelerationData] = useState([{region:[], dataset:"Oklahoma", events:[]}]);
    const [PolicyDataset, setPolicyDataset] = useState("United States") //OxCGRT, COVIDAMP
    const [EventCategories, setEventCategories] = useState([])//[{Region:[], PolicyDataset:'', categories:[]}]);
    const [SelectedRegionOfEventView, setSelectedRegionOfEventView] = useState(['United States', 'Oklahoma']);
    const getCountryISOCode = (selectedCountry) => {
        return CountryInfo.filter(obj => obj.name == selectedCountry).map(obj=>obj.isocode)[0]
    }

    //alert: the flask side function defaultly set the region to be in USA
    const loadMitigationData = (selectedRegion, selectedCountry=PickedCountry) => {
        // const contryIsoCode = getCountryISOCode(selectedCountry)
        fetch(mitigationDataModule,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'region':selectedRegion, 'policyDataset':PolicyDataset})})
        .then(response => response.json())
        .then((jsonData) => {
            // const regionId = getRegionId(contryIsoCode, selectedRegion)
//            console.log(jsonData)
            if (MitigationData[0].region.length==0) {
                setMitigationData([{region: selectedRegion, events:jsonData, dataset:PolicyDataset}])

            }else {
                // for (var item in jsonData.prediction) {
               
                    setMitigationData(prevState => 
                        [... prevState, {region: selectedRegion, events:jsonData, dataset:PolicyDataset}]);
                // }
            }
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    };

     //alert: the flask side function defaultly set the region to be in USA
    const loadAccelerationData=(selectedRegion, selectedCountry=PickedCountry)=>{
        fetch(acclerationDataModule,  {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({'region':selectedRegion, 'policyDataset':PolicyDataset})})
        .then(response => response.json())
        .then((jsonData) => {
            if (AccelerationData[0].region.length==0) {
                setAccelerationData([{region: selectedRegion, events:jsonData, 'dataset': PolicyDataset}])
            }else {
                setAccelerationData(prevState =>
                    [... prevState, {region: selectedRegion, events:jsonData, 'dataset': PolicyDataset}]);
            }
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    };
    /*************************************************************************/

    /*********Load covid Modeling*************************************************/
     // covid model selection
    const [modelSelectionList, setModelSelection] = useState([])
    const [PredictionData, setPredictionData] = useState([])
    const [SelectedRegionOfModelingView, setSelectedRegionOfModelingView] = useState(['United States', 'Oklahoma']);


    // const [PredictionData, setPredictionData] = useState([{
    //             paramestimation:{},
    //             prediction:[{time:"", modeledCases:null, modeledDeaths:null,
    //                             hospitalized:null, icu:null, recovered:null}],
    //             modelname:"",
    //             region:[]}]);
    // const [PredictionDataForComparison, setPredictionDataForComparison] = useState([{
    //             prediction:[{time:"", modeledCases:null, modeledDeaths:null, 
    //                         hospitalized:null, icu:null, recovered:null}], 
    //             modelname:"", 
    //             paramestimation:{}, 
    //             fips:""}]);
    //SEIR modeling's states -- obsolete
    const [R0_vals, setR0]  = useState(initDataFormat());

    const getPolicyData = (policydata, region)=> {
        let mindex = policydata.findIndex(x => isSameRegion(x.region, region));
        return (mindex==-1)? [] : policydata[mindex].events;
    }
            
    const loadCovidModelingResults = (selectedRegion, select_all_subregions) => {
        let mitigationCopy = getPolicyData(MitigationData, selectedRegion) //(mindex === -1) ? [] : MitigationData[mindex].events;
        let accelerationCopy =  getPolicyData(AccelerationData, selectedRegion) //aindex === -1 ? [] : AccelerationData[aindex].events;
        fetch(covidModelingResults, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify({
                'region':selectedRegion,
                'select_all_subregions': select_all_subregions,
                'dataSource':PickedDataSource, 
                'selectedModels': modelSelectionList, 
                'startDate': startPredictionDate, 
                'endDate': endPredictionDate,
                'mitigation':mitigationCopy,
                'acceleration':accelerationCopy,
                'policyDataTag': PolicyDataset})})
        .then(response=> response.json())
        .then((jsonData) => {
            console.log(jsonData)
            jsonData.forEach(dataPerRegion => {
                if (ObservationDataModeling.findIndex(item =>isSameRegion(item.region, dataPerRegion.region)) < 0) {
                    // console.log(selectedFipsCode)
                    setObservationDataModeling(prevState => [...prevState,
                        {'region':dataPerRegion.region, 'data':dataPerRegion.observation}])
                }

                 dataPerRegion.prediction.forEach((item, i)=>{
                    setPredictionData(prevState => [... prevState, item]);
                })
            })
    })};

  /*************************************************************************/

    const loadSimulationData = () => {
        setSimulationState(simulationState => {
            return ({
                ...simulationState,
                simulationDataIsLoading: true
            });
        });

        fetch(deftmodel, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => 
          res.json()
        )
        .then(json_data => {
            // let json_data = data[0]['data'];

            csv(unemploymentDataConstants.filename).then(counties => {
                // let merged_data = loadSimulationHelper(json_data[0]['data'], counties);
                // console.log(json_data);
                let merged_data = [];
                json_data.forEach(d => {
                    let data = loadSimulationHelper(d['data'], counties, d['abbr']);
                    merged_data = merged_data.concat(data);
                });
                // let ok_data = loadSimulationHelper(json_data[0]['data'], counties, unemploymentDataConstants.state);
                // let tx_data = loadSimulationHelper(json_data[1]['data'], counties, 'TX');
                // let merged_data = ok_data.concat(tx_data);
                // console.log(tx_data);
                // console.log(merged_data);
                setSimulationState(simulationState => {
                    return ({
                        ...simulationState,
                        simulationData: merged_data,
                        simulationDataIsLoading: false
                    })
                });
            });
        });
    };

    const loadObservedData = () => {
        csv(observedDataConstants.filename).then(counties => {
            let countyData = counties.map(d => {
                // let dates = d.date.split(observedDataConstants.forwardSlashSplit);
                return ({
                    id: d.fips,
                    state: d.state,
                    [observedDataConstants.casesField]: Number(d.cases),
                    [observedDataConstants.deathsField]: Number(d.deaths),
                    date: d.date
                    // date: observedDataConstants.dateYear + observedDataConstants.dateSeparator + dates[0] + 
                    //     observedDataConstants.dateSeparator + dates[1]
                });
            });

            let stateDic = {};
            countyData.forEach(el => {
                if (el.state !== 'Texas') {
                    return;
                }
                if (el.date in stateDic) {
                    stateDic[el.date][observedDataConstants.casesField] += el[observedDataConstants.casesField];
                    stateDic[el.date][observedDataConstants.deathsField] += el[observedDataConstants.deathsField];
                }
                else {
                    stateDic[el.date] = {
                        [observedDataConstants.casesField]: el[observedDataConstants.casesField],
                        [observedDataConstants.deathsField]: el[observedDataConstants.deathsField]
                    }
                }
            });

            let stateData = [];
            let keys = Object.keys(stateDic).sort((a, b) => {
                return new Date(a) - new Date(b);
            });

            keys.forEach(key => {
                stateData.push({
                    ...stateDic[key],
                    date:key
                })
            });

            setSimulationState(simulationState => {
                return ({
                    ...simulationState,
                    observedData: {
                        countyData: countyData,
                        stateData: stateData,
                        // startDate: keys[0]
                        startDate: "2020-3-13"
                    }
                });
            });
        });
    }

    const loadSTLData = () => {
        const handleSTLRow = (d, prevDate) => {
            let y = d.y === "inf" ? 0 : Number(d.y);
            let date = d.date;

            if (date === "NO DATE") {
                prevDate.setDate(prevDate.getDate() + 1);
                date = prevDate.getFullYear() + "-" + (prevDate.getMonth() + 1) + "-" + prevDate.getDate();
            }
            else {
                let tmpDate = new Date(date);
                prevDate.setTime(tmpDate.getTime());
            }

            return ({
                name: d.county,
                date: date,
                dead: y
            });
        };

        csv(STLDataConstants.filename).then(counties => {

            let prevDate = new Date();
            let countyData = counties.filter(d => d.county !== STLDataConstants.stateFilter).map(d => handleSTLRow(d, prevDate));
            let stateData = counties.filter(d => d.county === STLDataConstants.stateFilter).map(d => handleSTLRow(d, prevDate));

            csv(unemploymentDataConstants.filename).then(counties => {
                let mergedCounty = loadSimulationHelper(countyData, counties);

                setSimulationState(simulationState => {
                    return ({
                        ...simulationState,
                        STLData: {
                            countyData: mergedCounty,
                            stateData: stateData
                        }
                    });
                });
            });
        });
    }

    const loadModelParams = () => {
        fetch(defaultparams, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => 
            res.json()
        )
        .then(data => {
            setSimulationState(simulationState => {
                return ({
                    ...simulationState,
                    modelParams: data
                });
            });
        });
    }

    useLayoutEffect(() => {
        // loadObservedData();
        // loadSTLData();
        fetch(initBackend, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
             })
            .then(response=> response.json())
            .then(jsonData => {
                    loadStateInfo(PickedCountry);
                    loadCountyInfo(PickedRegionModeling);
                    loadCovidObservations(PickedRegionModeling);
                    loadCovidObservationsForMap(RegionOnMap);
                    loadMitigationData(PickedRegionModeling);
                    loadAccelerationData(PickedRegionModeling);
                    loadSimulationData();
                    loadModelParams();
            });
    }, []);

    useLayoutEffect(() => {
        if (simulationState.simulationData.length) {
            let highestRatio = 0;

            if (colorState.colorings.length) {
                highestRatio = Math.max(
                    ...simulationState.simulationData.map(d => {
                        let arr = d[colorState.colorings[0]].slice();

                        (colorState.colorings.slice(1)).forEach((el) => {
                            arr = arr.map((e, i) => e + d[el][i]);
                        });

                        let a =  arr.map(e => e / d.population);
                        for (let i = 0; i < a.length; ++i) {
                            a[i] = Number(a[i]);
                            if (isNaN(a[i])) {
                                a[i] = 0;
                            }
                        }
                        return a;
                    }).flat(1)
                ) / 9;
            }
            // let k = simulationState.simulationData.map(d => {
            //     let arr = d[colorState.colorings[0]].slice();

            //     (colorState.colorings.slice(1)).forEach((el) => {
            //         arr = arr.map((e, i) => e + d[el][i]);
            //     });

            //     let a =  arr.map(e => e / d.population);
            //     return a;
            // });

            // k = k.flat(1);
            // console.log(k);
            // for (let i = 0; i < k.length; ++i) {
            //     let tmp = k[i];
            //     k[i] = Number(k[i]);
            //     if (isNaN(k[i])) {
            //         console.log(tmp);
            //         console.log(i);
            //     }
            // }
            
            setColorState(colorState => {
                return ({
                    ...colorState,
                    colorScale: highestRatio
                });
            })
        }
    }, [colorState.colorings, simulationState.simulationData]);

    useLayoutEffect(() => {
        //update EventCategories
        var newEventCategories = []
        function extractCategory(item) {
            if (item.region.length > 0) {
                let arrs = newEventCategories.filter(x=>isSameRegion(x.region, item.region) && (x.dataset == item.dataset))
                let target = {}//{region:[], dataset:'', categories:[]}
                if (arrs.length > 0) {
                    target = arrs[0]
                } else {
                    target['categories'] = []//= policyCategories[item.dataset]
                    target['region']  = item.region
                    target['dataset'] = item.dataset
                    newEventCategories.push(target)
                }
                let policies = item.events
                policies.forEach(function (p, index2) {
                    let category = p['category']
                    if(category != '' && category != undefined && target['categories'].findIndex(x => x==category) < 0) {
                        target['categories'].push(category)
                    }
                })
            }
        }
        MitigationData.forEach(function (item, index) {
            extractCategory(item)
        });
        AccelerationData.forEach(function (item, index) {
            extractCategory(item)
        });
        setEventCategories(newEventCategories)
    }, [MitigationData, AccelerationData])

    useEffect(() => {
        const getColorings = () => {
            let colorings = [];

            if (colorState.coloringShow === colorConstants.colorValues.colorAllCasesValue) {
                if (colorState.coloringCategory === colorConstants.colorValues.colorAllSickValue) {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorAllSickValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorAllAgeLowSickValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorAllAgeMidSickValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorAllAgeHighSickValue);
                        }
                    }
                }
                else if (colorState.coloringCategory === colorConstants.colorValues.colorAllDeadValue) {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorAllDeadValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorAllAgeLowDeadValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorAllAgeMidDeadValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorAllAgeHighDeadValue);
                        }
                    }
                }
                else {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorAllHospitalizedValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorAllAgeLowHospitalizedValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorAllAgeMidHospitalizedValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorAllAgeHighHospitalizedValue);
                        }
                    }
                }
            }
            else {
                if (colorState.coloringCategory === colorConstants.colorValues.colorAllSickValue) {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorNewSickValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorNewAgeLowSickValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorNewAgeMidSickValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorNewAgeHighSickValue);
                        }
                    }
                }
                else if (colorState.coloringCategory === colorConstants.colorValues.colorAllDeadValue) {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorNewDeadValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorNewAgeLowDeadValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorNewAgeMidDeadValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorNewAgeHighDeadValue);
                        }
                    }
                }
                else {
                    if (populationState.showUnderEighteen && populationState.showEighteenToSixtyFive && populationState.showSixtyfiveOver) {
                        colorings.push(colorConstants.colorValues.colorNewHospitalizedValue);
                    }
                    else {
                        if (populationState.showUnderEighteen) {
                            colorings.push(colorConstants.colorValues.colorNewAgeLowHospitalizedValue);
                        }
                        if (populationState.showEighteenToSixtyFive) {
                            colorings.push(colorConstants.colorValues.colorNewAgeMidHospitalizedValue);
                        }
                        if (populationState.showSixtyfiveOver) {
                            colorings.push(colorConstants.colorValues.colorNewAgeHighHospitalizedValue);
                        }
                    }
                }
            }

            return colorings;
        }

        setColorState(colorState => {
            return ({
                ...colorState,
                colorings: getColorings()
            });
        });
    }, [colorState.coloringShow, colorState.coloringCategory, populationState]);

    const handleToggleSimulation = () => {
        if (!simulationState.simulationIsRunning) {
            setSimulationState(simulationState => {
                return ({
                    ...simulationState,
                    intervalId: setInterval(() => {
                        setSimulationState(simulationState => {
                            return ({
                                ...simulationState,
                                simulationTimeStep: Number(simulationState.simulationTimeStep) + 1
                            });
                        });
                    }, simulationState.simulationSpeed),
                });
            });
        }
        else {
            clearInterval(simulationState.intervalId);
        }
    
        setSimulationState(simulationState => {
            return ({
                ...simulationState,
                simulationIsRunning: !simulationState.simulationIsRunning
            });
        });
    };
    
    const handleColorCategoryChange = (evt) => {
        let val = evt.target.value;
//        console.log(val)
        setColorState(colorState => {
            return ({
                ...colorState,
                coloringCategory: val
            });
        });
        setMapDataCategory(val)
    }
    
    const handleColorShowChange = (evt) => {
        let val = evt.target.value;
    
        setColorState(colorState => {
            return ({
                ...colorState,
                coloringShow: val
            });
        });
    }
    
    const handlePopulationChange = (evt) => {
        let name = evt.target.name
    
        setPopulationState(populationState => {
            return ({
                ...populationState,
                [name]: !populationState[name]
            });
        });
    }
    
    const handleParameterChange = (evt) => {
        let name = evt.target.name;
        let val = evt.target.value;
    
        if (isNaN(Number(val))) {
            alert(name + ' must be a decimal.');
        }
        else {
            setSimulationState(simulationState => {
                return ({
                    ...simulationState, 
                    modelParams: {
                        ...simulationState.modelParams,
                        [name]: val
                    }
                });
            });
        }
    }
    
    const handleParameterCheckboxChange = (evt) => {
        let name = evt.target.name;
    
        setSimulationState(simulationState => {
            return ({
                ...simulationState,
                modelParams: {
                    ...simulationState.modelParams,
                    [name]: !simulationState.modelParams[name]
                }
            });
        });
    }
    
    const handleSubmitParameters = (evt) => {
        let typedParams = {}
        Object.keys(simulationState.modelParams).forEach((key) => {
            typedParams[key] = Number(simulationState.modelParams[key])
        });
    
        fetch(setparams, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(typedParams)
        })
        .then(res => res.json())
        .then(data => {
            loadSimulationData();
        });
    }
    
    const handleResetParameters = (evt) => {
        
    }

    const getRegionCode = (region) => {
        if (region.length==1) {
            let arr = COUNTRY_Alpha_3_Code.filter(element =>element.name == region[0])
            return arr[0].code
        }
        else if (region.length==2){
            if (region[0] == 'United States') {
                let arr = StateInfo.filter(element => element.name == region[1])
                return arr[0].fips
            }
            return ''
        }
    };


    const value = {
        // loadSEIRData,
        loadCovidModelingResults,
        // loadDataForMap, 
        loadCovidObservationsForMap,
        // appendCovidModelingResults,
        // setCovidModelState,
        // covidModelingVisState,
        isTrainModel, setIsTrainModel,
        startTrainingDate, setTrainingStartDate,
        endTrainingDate, setTrainingEndDate,
        startPredictionDate, setPredictionStartDate,
        endPredictionDate, setPredictionEndDate,
        // loadDELPHIModel,
        simulationState, setSimulationState,    
        colorState, setColorState,
        populationState, setPopulationState,
        handleToggleSimulation,
        handleColorCategoryChange,
        handleColorShowChange,
        handlePopulationChange,
        handleParameterChange,
        handleParameterCheckboxChange,
        handleSubmitParameters,
        handleResetParameters,
        // setDataSourceTag,
        setModelSelection,
        setCountyInfo,
        setPickedRegionModeling,
        PickedCountry, setPickedCountry,
        PickedDataSource, setPickedDataSource,
        modelSelectionList,
        // showSEIRModel, 
        // setSEIRModelVisibility,
        R0_vals,
        AgeDistribution_SEIR,
        // CasesCounts,
        // ScenarioInfo,
        // datasourceTag,
        PredictionData, setPredictionData,
        ObservationDataModeling, setObservationDataModeling,
        ObservationDataMap, setObservationDataMap,
        // startMapDate, setMapStartDate,
        // endMapDate, setMapEndDate,
        MapDisplayDate, setMapDisplayDate,
        CountryInfo, setCountryInfo,
        StateInfo, setStateInfo, loadStateInfo,
        CountyInfo, loadCountyInfo,
        PickedRegionModeling,
        RegionOnMap, SetRegionOnMap,
        MitigationData, setMitigationData, loadMitigationData,
        AccelerationData, setAccelerationData, loadAccelerationData,
        // PredictionDataForComparison, setPredictionDataForComparison,
        EventCategories, setEventCategories,
        SelectedRegionOfEventView, setSelectedRegionOfEventView,
        PolicyDataset, setPolicyDataset,
        getCountryISOCode, getRegionCode,
        MapDisplayDataOption, setMapDisplayDataOption,
        MapDataCategoryList, setMapDataCategoryList,
        MapDataCategory, setMapDataCategory,
        SelectedRegionOfModelingView, setSelectedRegionOfModelingView,
        // ModelOptions
    }

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    );
}

export {loadSimulationHelper, GlobalContext, GlobalContextProvider};

  // const loadSEIRData=(selectedFipsCode)=>{
    //     fetch(seirmodel,  {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }, body: JSON.stringify({'fipsCode':selectedFipsCode})})
    //     .then(response => response.json())
    //     .then((jsonData) => {
    //       setR0({
    //         trajectory:jsonData.trajectory,
    //         R0:jsonData.R0
    //       })
      
    //       fetch(seirage, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }, body: JSON.stringify({'fipsCode':selectedFipsCode})})
    //       .then(response => response.json())
    //       .then((jsonData) => {
    //         // setAgeInfo(updateAgeInfo(jsonData))
    //         setAgeInfo(jsonData.data)
    //         // console.log(jsonData.data)
      
    //         fetch(seirObservedData, {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             }, body: JSON.stringify({'fipsCode':selectedFipsCode})})
    //         .then(response => response.json())
    //         .then((jsonData) => {
    //           setCasesCounts({data:jsonData.data, name:jsonData.name})
    //         // console.log(CasesCounts.data[1])
      
    //           fetch(seirinfo, {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             }, body: JSON.stringify({'fipsCode':selectedFipsCode})})
    //           .then(response => response.json())
    //           .then((jsonData) => {
    //             setScenarioInfo({
    //                 epidemiological:jsonData.data.epidemiological,
    //                 mitigation:jsonData.data.mitigation,
    //                 // population:jsonData.data.population,
    //                 population: {ageDistributionName: jsonData.data.population.ageDistributionName,caseCountsName: jsonData.data.population.caseCountsName,
    //                   hospitalBeds: jsonData.data.population.hospitalBeds, icuBeds: jsonData.data.population.icuBeds,importsPerDay: jsonData.data.population.importsPerDay,
    //                   initialNumberOfCases: jsonData.data.population.initialNumberOfCases,populationServed: jsonData.data.population.populationServed,seroprevalence: jsonData.data.population.seroprevalence},
    //                 simulation:jsonData.data.simulation 
    //           })})
    //           .catch((error) => {
    //           // handle your errors here
    //           console.error(error)
    //           })
    //         })
    //         .catch((error) => {
    //         // handle your errors here
    //         console.error(error)
    //         })
    //       })
    //       .catch((error) => {
    //         // handle your errors here
    //         console.error(error)
    //       })
    //     })
    //     .catch((error) => {
    //       // handle your errors here
    //       console.error(error)
    //     })
    // };

     // const [ScenarioInfo, setScenarioInfo] = useState({
    //     epidemiological: {hospitalStayDays: 0, icuStayDays: 0, infectiousPeriodDays: 0, latencyDays: 0, overflowSeverity: 0, peakMonth: 0, r0: {begin: 0,end: 0},seasonalForcing: 0.0},
    //     // mitigation: {mitigationIntervals: [{color: null,name: "",timeRange: {begin: "",end: ""},transmissionReduction: {begin: 0, end: 0}}]},
    //     // acceleration: {interval: [{color: null,name: "",timeRange: {begin: "",end: ""},transmissionIncrease: {begin: 0, end: 0}}]},
    //     population: {ageDistributionName: "",caseCountsName: "",hospitalBeds: 0,icuBeds: 0,importsPerDay: 0.1,initialNumberOfCases: 0,populationServed: 0,seroprevalence: 0},
    //     simulation: {numberStochasticRuns: 0, simulationTimeRange: {begin: "",end: ""}}
    // });


         // setObservationData(jsonData.observation);
            //     setScenarioInfo({
            //         epidemiological:jsonData.scenario.data.epidemiological,
            //         mitigation:jsonData.scenario.data.mitigation,
            //         acceleration: jsonData.scenario.data.acceleration,
            //         population: {ageDistributionName: jsonData.scenario.data.population.ageDistributionName,caseCountsName: jsonData.scenario.data.population.caseCountsName,
            //           hospitalBeds: jsonData.scenario.data.population.hospitalBeds, icuBeds: jsonData.scenario.data.population.icuBeds,importsPerDay: jsonData.scenario.data.population.importsPerDay,
            //           initialNumberOfCases: jsonData.scenario.data.population.initialNumberOfCases,populationServed: jsonData.scenario.data.population.populationServed,seroprevalence: jsonData.scenario.data.population.seroprevalence},
            //         simulation:jsonData.scenario.data.simulation 
            //   });   

    
    // const appendCovidModelingResults = (selectedFipsCode)=>{
    //     fetch(covidModelingResults, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }, body: JSON.stringify({
    //             'fipsCode':selectedFipsCode, 
    //             'dataSource':PickedDataSource, 
    //             'selectedModels': modelSelectionList, 
    //             'startDate': startPredictionDate, 
    //             'endDate': endPredictionDate,
    //             'mitigation':MitigationData,
    //             'acceleration':AccelerationData})})
    //     .then(response=> response.json())
    //     .then((jsonData) => {    
    //         if (ObservationData.findIndex(item =>item.fips == selectedFipsCode) < 0) {
    //             console.log(selectedFipsCode)
    //             setObservationData(prevState => [...prevState, {'fips':selectedFipsCode, 'data':jsonData.observation}])
    //         }
    //         for (var item in jsonData.prediction) {
    //             setPredictionDataForComparison(prevState => 
    //                 [... prevState, jsonData.prediction[item]]);
    //         }
    // })};